<template>
	<div>
		<div class="mb-1 flex flex-row items-center gap-2">
			<h3 class="text-xs font-medium text-black">Pinned</h3>
			<client-only>
				<button
					class="pr-4"
					@click.prevent="preferences.sidebar.favorites_minimized = !preferences.sidebar.favorites_minimized">
					<animated-chevron-icon
						:mode="preferences.sidebar.favorites_minimized ? 'right' : 'down'"
						class="h-2.5 w-2.5" />
				</button>
			</client-only>
		</div>
		<client-only>
			<Collapse
				:when="!preferences.sidebar.favorites_minimized"
				class="!duration-100">
				<div
					ref="favoritesDragContainer"
					:class="{ 'bg-secondary bg-opacity-10': dragTarget != null && isMouseOver }"
					class="rounded-sm">
					<div
						v-for="element of favoritesModel"
						:key="element._id">
						<sidebar-page-editor
							v-if="element.itemType === 'page'"
							:page="element.data as Page" />
						<button
							v-if="element.itemType === 'component' && element.data.componentType === 'flash_cards'"
							class="my-0.5 ml-1.5 flex flex-row items-center gap-1 px-2 py-1 text-xs text-neutral-800 transition-all"
							@click="navigateTo(`/page/${element.data.pageId}/study/${element.itemId}`)">
							<FlashcardsIcon class="h-3 w-3 text-gray-700" />
							<span>
								{{ element.data.title ? shortenText(element.data.title, 25) : "Untitled Flashcards" }}
							</span>
						</button>
						<button
							v-if="element.itemType === 'component' && element.data.componentType === 'image'"
							class="my-0.5 ml-1.5 flex flex-row items-center gap-1 px-2 py-1 text-xs text-neutral-800 transition-all"
							@click="navigateTo(`/pdf/${element.itemId}`)">
							<FileTextIcon class="h-3 w-3 text-gray-700" />
							<span>
								{{ shortenText(element.data.name, 25) }}
							</span>
						</button>
					</div>
				</div>
			</Collapse>
		</client-only>
	</div>
</template>
<script lang="ts" setup>
import { FileTextIcon } from "lucide-vue-next";

import { Page } from "~/utils/page";
import { Collapse } from "vue-collapsed";

const { favorites } = useFavorites();
const { pages } = usePages();

const dragTarget = usePagesDrag().dragTarget;
const isMouseOver = usePagesDrag().isDragOverFavorite;

const favoritesDragContainer = ref<HTMLElement | null>(null);
const { preferences } = useUserPreferences();

const favoritesModel = computed(() => {
	//populate the page favorites with the data from pages
	favorites.value.forEach((f) => {
		if (f.itemType === "page") {
			const page = pages.value.find((p) => p._id === f.itemId);
			if (page) {
				f.data = page;
			}
		}
	});

	return favorites.value.sort((a, b) => a.sortIndex - b.sortIndex);
});

onMounted(() => {
	window.addEventListener("drag", onMouseMove);
});

onUnmounted(() => {
	window.removeEventListener("drag", onMouseMove);
});

function onMouseMove(event: DragEvent) {
	if (!favoritesDragContainer.value) {
		return;
	}

	const rect = favoritesDragContainer.value.getBoundingClientRect();
	isMouseOver.value = event.clientX >= rect.left && event.clientX <= rect.right && event.clientY >= rect.top && event.clientY <= rect.bottom;
}
</script>
