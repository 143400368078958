<template>
	<svg
		fill="none"
		height="24"
		viewBox="0 0 24 24"
		width="24"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M21 12.5V5.5C21 4.67157 20.3284 4 19.5 4H17H12.5C11.7 4 11 4.7 11 5.5V12.5C11 13.3 11.7 14 12.5 14H19.5C20.3 14 21 13.3 21 12.5Z"
			stroke="currentColor"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="2" />
		<path
			d="M7 12V17.28C7 17.46 7.15 17.64 7.3 17.76C7.45 17.88 7.675 18 7.9 18H13"
			stroke="currentColor"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="2" />
		<path
			d="M3 16V21.28C3 21.46 3.15 21.64 3.3 21.76C3.45 21.88 3.675 22 3.9 22H9"
			stroke="currentColor"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="2" />
	</svg>
</template>
