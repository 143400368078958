<template>
	<unstyled-modal :should-close="shouldClose">
		<div
			:style="extraStyles"
			class="rounded-md bg-white shadow-sm"
			role="dialog">
			<div class="transition-opacity">
				<div class="flex flex-row justify-stretch space-x-4">
					<div class="min-h-full min-w-[250px] max-w-[250px] rounded-l-md bg-gray-100 px-4 py-3">
						<div
							v-if="title"
							class="flex flex-row items-center justify-between">
							<h1 class="text-lg font-bold text-black">{{ title }}</h1>
							<button
								class="rounded-full border border-gray-200 bg-gray-50 p-1 shadow-sm transition-colors hover:bg-gray-100"
								@click.prevent="shouldClose = true">
								<XMarkIcon class="h-4 w-4 text-gray-700"></XMarkIcon>
							</button>
						</div>
						<div>
							<slot name="sidebar"></slot>
						</div>
					</div>
					<div class="grid h-auto flex-grow px-4 py-3">
						<slot name="main"></slot>
					</div>
				</div>
			</div>
		</div>
	</unstyled-modal>
</template>
<script lang="ts" setup>
import { XMarkIcon } from "@heroicons/vue/24/solid";

defineProps({
	title: {
		type: String || null,
		required: false,
	},
	extraStyles: {
		type: Object,
		required: false,
	},
});

const shouldClose = ref(false);
</script>
