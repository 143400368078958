<template>
	<div
		ref="parentContainer"
		class="absolute z-50"
		:style="overridePrimaryStyle"
		data-sc-dropdown>
		<div
			v-if="!styled"
			ref="container">
			<slot />
		</div>
		<div
			v-if="styled"
			ref="container"
			:class="{ 'dark:border-none dark:bg-neutral-800': dark, [extraClasses]: true }"
			:style="`width: ${width};`"
			class="rounded-md border border-gray-150 bg-white px-1 py-1 text-sm text-gray-600 shadow-sm">
			<slot />
		</div>
	</div>
</template>
<script lang="ts" setup>
import { PopupContent } from "~/composables/usePopupContent";

const container = ref<HTMLElement>();
const parentContainer = ref<HTMLElement>();
const emits = defineEmits({
	close: () => true,
});

defineProps({
	styled: {
		type: Boolean,
		required: false,
		default: true,
	},
	width: {
		type: String,
		required: false,
		default: "200px",
	},
	extraClasses: {
		type: String,
		required: false,
		default: "",
	},
	dark: {
		type: Boolean,
		required: false,
		default: false,
	},

	overridePrimaryStyle: {
		type: String,
		required: false,
		default: "",
	},
});

function handleInputs(e: KeyboardEvent) {
	if (e.key === "Enter") {
		const focusedButton = container.value!!.querySelector("[data-sc-dropdown-btn]:focus");
		if (focusedButton) {
			(focusedButton as HTMLButtonElement).click();
			e.preventDefault();
		}
	}
	if (e.key !== "ArrowDown" && e.key !== "ArrowUp") {
		return;
	}

	if (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) return;

	const buttons = container.value!!.querySelectorAll("[data-sc-dropdown-btn]");
	const focusedButton = container.value!!.querySelector("[data-sc-dropdown-btn]:focus");

	if (!focusedButton) {
		(buttons[0] as HTMLButtonElement).focus();
		return;
	}

	const focusedButtonIndex = Array.from(buttons).indexOf(focusedButton);
	if (e.key == "ArrowDown") {
		if (focusedButtonIndex == buttons.length - 1) {
			(buttons[0] as HTMLButtonElement).focus();
		} else {
			(buttons[focusedButtonIndex + 1] as HTMLButtonElement).focus();
		}
	} else if (e.key == "ArrowUp") {
		if (focusedButtonIndex == 0) {
			(buttons[buttons.length - 1] as HTMLButtonElement).focus();
		} else {
			(buttons[focusedButtonIndex - 1] as HTMLButtonElement).focus();
		}
	}
}

const { registerContent, destroyContent } = usePopupContent();
let popupElement: PopupContent | undefined = undefined;

onMounted(() => {
	popupElement = registerContent({
		element: container.value!!,
		closeListener: () => emits("close"),
	});

	document.addEventListener("keydown", handleInputs);
});

onUnmounted(() => {
	destroyContent(popupElement!!);
	document.removeEventListener("keydown", handleInputs);
});
</script>
