import axios from "axios";
import { ConversationLinkedModelType, IUserConversation, UserConversationType } from "~/utils/conversation";
import { Component, Page } from "~/utils/page";

export const useConversation = () => {
	const pastConversations = useState<IUserConversation[]>("past_conversations", () => []);
	const pastConversationsLoaded = useState<boolean>("past_conversations_loaded", () => false);
	const currentLinkedTextString = useState<string[]>("current_linked_text_string", () => []);
	const toLinkComponents = useState<Component[]>("to_link_components", () => []);
	const toLinkPages = useState<Page[]>("to_link_pages", () => []);

	async function createConversation(modelId: string, modelType: ConversationLinkedModelType, convoType: UserConversationType): Promise<IUserConversation | null> {
		try {
			const { data } = await axios.post("/conversation/new_conversation", {
				linked_model_id: modelId,
				linked_model_type: modelType,
				type: convoType,
			});

			getPosthog().capture("conversation_created", {
				type: convoType,
			});

			return data;
		} catch (e) {
			console.error(e);
			return null;
		}
	}

	async function fetchPastConversations() {
		try {
			const { data } = await axios.get("/conversation/past_conversations");

			pastConversations.value.push(...data);
		} catch (e) {
			addAlert("An issue occurred while fetching your past conversations.", "error");
			console.error(e);
		} finally {
			pastConversationsLoaded.value = true;
		}
	}

	function appendLinkedTextString(text: string) {
		currentLinkedTextString.value.push(text);
		getPosthog().capture("linked_text_string_appended", {
			text,
		});
	}

	function linkComponent(component: Component) {
		if (toLinkComponents.value.find((c) => c._id === component._id)) {
			return;
		}
		toLinkComponents.value.push(component);

		getPosthog().capture("component_linked_to_conversation", {});
	}

	function linkPage(page: Page) {
		if (toLinkPages.value.find((p) => p._id === page._id)) {
			return;
		}
		toLinkPages.value.push(page);

		getPosthog().capture("page_linked_to_conversation", {});
	}

	return {
		createConversation,
		pastConversations,
		currentLinkedTextString,
		appendLinkedTextString,
		fetchPastConversations,
		linkPage,
		linkComponent,
		pastConversationsLoaded,
		toLinkComponents,
		toLinkPages,
	};
};
