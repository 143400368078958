<template>
	<div v-if="recents.length > 0">
		<div class="mb-1 flex flex-row items-center gap-2">
			<h3 class="text-xs font-medium text-black">Recently Viewed</h3>
			<client-only>
				<button
					class="pr-4"
					@click.prevent="preferences.sidebar.recents_minimized = !preferences.sidebar.recents_minimized">
					<animated-chevron-icon
						:mode="preferences.sidebar.recents_minimized ? 'right' : 'down'"
						class="h-2.5 w-2.5" />
				</button>
			</client-only>
		</div>
		<client-only>
			<Collapse
				:when="!preferences.sidebar.recents_minimized"
				class="!duration-100">
				<div
					ref="favoritesDragContainer"
					:class="{ 'bg-secondary bg-opacity-10': dragTarget != null && isMouseOver }"
					class="rounded-sm">
					<div
						v-for="element of recents"
						:key="element.model._id">
						<sidebar-page-editor
							v-if="element.type === 'pages'"
							:page="element.model as Page" />
						<button
							v-if="element.type === 'flashcards'"
							class="my-0.5 ml-1.5 flex flex-row items-center gap-1 px-2 py-1 text-xs text-neutral-800 transition-all"
							@click="navigateTo(element.url)">
							<FlashcardsIcon class="h-3 w-3 text-red-500" />
							<span>
								{{ element.title ? shortenText(element.title, 25) : "Untitled Flashcards" }}
							</span>
						</button>
						<button
							v-if="element.type === 'PDFs'"
							class="my-0.5 ml-1.5 flex flex-row items-center gap-1 px-2 py-1 text-xs text-neutral-800 transition-all"
							@click="navigateTo(element.url)">
							<FileTextIcon class="h-3 w-3 text-blue-500" />
							<span>
								{{ shortenText(element.title, 25) }}
							</span>
						</button>
					</div>
				</div>
			</Collapse>
		</client-only>
	</div>
</template>
<script lang="ts" setup>
import { FileTextIcon } from "lucide-vue-next";

import { Page } from "~/utils/page";
import { Collapse } from "vue-collapsed";

const { getRecents } = usePages();

const dragTarget = usePagesDrag().dragTarget;
const isMouseOver = usePagesDrag().isDragOverFavorite;

const favoritesDragContainer = ref<HTMLElement | null>(null);
const { preferences } = useUserPreferences();

const recents = computed(() => {
	return getRecents.value.filter((x) => x.type != "image").slice(0, 5);
});

onMounted(() => {
	window.addEventListener("drag", onMouseMove);
});

onUnmounted(() => {
	window.removeEventListener("drag", onMouseMove);
});

function onMouseMove(event: DragEvent) {
	if (!favoritesDragContainer.value) {
		return;
	}

	const rect = favoritesDragContainer.value.getBoundingClientRect();
	isMouseOver.value = event.clientX >= rect.left && event.clientX <= rect.right && event.clientY >= rect.top && event.clientY <= rect.bottom;
}
</script>
