<template>
	<transition
		enter-active-class="menu-transition duration-100 ease-out"
		enter-from-class="scale-95 transform opacity-0"
		enter-to-class="scale-100 transform opacity-100"
		leave-active-class="menu-transition duration-75 ease-in"
		leave-from-class="scale-100 transform opacity-100"
		leave-to-class="scale-95 transform opacity-0">
		<slot />
	</transition>
</template>
<script lang="ts" setup></script>
<style scoped>
.menu-transition {
	@apply transition-opacity transition-transform;
}
</style>
