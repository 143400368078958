export enum Theme {
	Light = "light",
	Dark = "dark",
	System = "system",
}

export function getTheme(): Theme {
	if (localStorage.theme === "dark") {
		return Theme.Dark;
	} else if (localStorage.theme === "light") {
		return Theme.Light;
	} else {
		return Theme.System;
	}
}

export function figureOutTheme() {
	if (localStorage.theme === "dark" || (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches)) {
		document.documentElement.classList.add("dark");
	} else {
		document.documentElement.classList.remove("dark");
	}
}

export function setTheme(theme: Theme) {
	localStorage.theme = theme;

	if (theme === Theme.Dark) {
		document.documentElement.classList.add("dark");
	}

	if (theme === Theme.Light) {
		document.documentElement.classList.remove("dark");
	}

	if (theme === Theme.System) {
		if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
			document.documentElement.classList.add("dark");
		} else {
			document.documentElement.classList.remove("dark");
		}
	}
}
