import { marked } from "marked";
import katex from "katex";
import Prism from "prismjs";
import { usingPrismImportLangs } from "~/utils/prism_languages";

usingPrismImportLangs();

export function lazyImgMarked(markdown: string) {
	const renderer = new marked.Renderer();
	renderer.image = ({ href, title, text }) => {
		return `<img src="${href}" alt="${text}" alt="${title}" loading="lazy" />`;
	};
	return marked(markdown, { renderer });
}

export function renderStyledText(text: string, { markdown = true, latex = true, textHighlighting = true }) {
	if (markdown) {
		if (!textHighlighting) {
			//@ts-ignore
			text = marked(text);
		} else {
			const renderer = new marked.Renderer();

			renderer.code = ({ text, lang, escaped }) => {
				if (!lang) {
					return `<pre><text>${text}</text></pre>`;
				}
				try {
					const highlighted = Prism.highlight(text, Prism.languages[lang], lang);
					return `<div class="bg-gray-50 py-2 px-4 shadow-sm border border-gray-100 rounded-md"><pre><text class="font-mono">${highlighted}</text></pre></div>`;
				} catch (e) {
					console.error(e);
					return `<pre><text>${text}</text></pre>`;
				}
			};
			//@ts-ignore
			text = marked(text, {
				renderer: renderer,
			});
		}
	}

	if (latex) {
		text = formatLatex(text).html;
	}

	return text;
}

export interface LatexDetectionResult {
	original: string;
	html: string;
}

export function formatLatex(input: string): LatexDetectionResult {
	const latexRegex = /\$\$.*?\$\$/g;
	const displayMode = input.match(latexRegex);

	let html = input;
	if (displayMode) {
		for (let l of displayMode) {
			const rendered = katex.renderToString(l.replaceAll("$$", ""), {
				throwOnError: false,
				displayMode: true,
				output: "html"
			});

			//this error is thrown: ParseError: KaTeX parse error: Can't use function '$' in math mode at position 1.

			html = html.replace(l, `<div class="latex">${rendered}</div>`);
		}
	}

	const singularLatexRegex = /\$.*?\$/g;
	const singularLatex = input.match(singularLatexRegex);
	if (singularLatex) {
		for (let l of singularLatex) {
			const rendered = katex.renderToString(l.replaceAll("$", ""), {
				throwOnError: false,
				displayMode: false,
				output: "html"
			});

			html = html.replace(l, `<span class="latex">${rendered}</span>`);
		}
	}

	return {
		original: input,
		html
	};
}
