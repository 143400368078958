export function insertItemAtIndex<T>(array: T[], index: number, item: T) {
	if (index < 0) {
		index = 0;
	} else if (index > array.length) {
		index = array.length;
	}

	array.splice(index, 0, item);
}

export function removeItem<T>(array: T[], item: T) {
	const index = array.indexOf(item);

	if (index > -1) {
		array.splice(index, 1);
	}
}

export function insertBelowItem<T>(array: T[], item: T, below: T | null): void {
	const index = array.indexOf(below);

	if (index > -1) {
		array.splice(index + 1, 0, item);
	} else {
		array.push(item);
	}
}

export function insertItemBetween<T>(array: T[], itemToInsert: T, a: T | null, b: T | null): void {
	insertBelowItem(array, itemToInsert, a);
}

export function insertItemsBelow<T>(array: T[], itemsToInsert: T[], item: T): void {
	const index = array.indexOf(item);

	if (index > -1) {
		array.splice(index + 1, 0, ...itemsToInsert);
	}
}

export function pickRandomElementWithBias<T>(array: T[], biasProvider: (element: T) => number): T {
	const totalBias = array.reduce((total, element) => total + biasProvider(element), 0);
	const random = Math.random() * totalBias;

	let currentBias = 0;

	for (const element of array) {
		currentBias += biasProvider(element);

		if (currentBias >= random) {
			return element;
		}
	}

	throw new Error("No element found");
}
