import axios from "axios";
import { sendToLastPage, User } from "~/utils/user";
import { api_url } from "~/utils/api";

export default defineNuxtPlugin(async () => {
	//@ts-ignore
	axios.defaults.baseURL = api_url + "/v1";
	const { fetchUser, user, getAuthToken, tempToken, serverPagesData, serverFavoritesData } = useAuth();
	const { parseFetchedPagesData, loadingPages } = usePages();
	const { favorites } = useFavorites();
	const { fetchChartData } = useHomeData();

	const token = getAuthToken();
	let route = useRoute();

	const fetchUserTimeTaken = useState<number>("auth_plugin.ts-time-taken-fetch-user", () => 0);

	if (!token.value) return;

	try {
		if (user.value) {
			axios.defaults.headers.common["Authorization"] = `Bearer ${tempToken.value !== null ? tempToken.value : token.value}`;
			return;
		}

		const doUserFetch = async () => {
			const now = new Date().getTime();
			await fetchUser();
			fetchUserTimeTaken.value = new Date().getTime() - now;
		};

		const now_ms = new Date().getTime();
		await Promise.all([doUserFetch()]);
		console.log("Total time taken for auth plugin fetch: " + (new Date().getTime() - now_ms));

		parseFetchedPagesData(serverPagesData.value);
		favorites.value = serverFavoritesData.value;

		serverFavoritesData.value = null;
		loadingPages.value = false;
	} catch (e) {
		console.error(e);
		return;
	}

	//handle onboarding
	if (user.value && !(user.value as User).onboardingComplete) {
		//ensure current route is not onboarding
		if (route.path == "/onboarding") return;

		//redirect to onboarding
		await navigateTo("/onboarding");
		console.log(`Redirecting to onboarding`);
		return;
	}

	//handle logged in redirect when going to '/'
	if (route.path === "/") {
		console.log(`Redirecting to last page, as route is '/' and the user is logged in.`);
		await sendToLastPage();
		return;
	}

	//check if it's server
	if (!route.query.from_auth && (route.path === "/login" || route.path === "/register")) {
		console.log(`Redirecting to last page, as route is '${route.path}' and the user is logged in.`);
		await sendToLastPage();
	}
});
