<template>
	<div class="mx-4 px-4 py-4">
		<div class="flex flex-row items-start gap-2">
			<div class="flex h-4 w-4 flex-col items-center justify-center rounded-full border border-purple-200 bg-purple-50">
				<BotIcon class="w-6 text-purple-400" />
			</div>
			<h1 class="text-xs font-bold">
				{{ "Scholarly Assistant" }}
			</h1>
		</div>
		<div v-if="response">
			<div
				class="ai-chat-message-style mt-2"
				v-html="renderStyledText(response, { latex: true, markdown: true })"></div>
			<div class="inline-block">
				<nuxt-link
					:to="`/assistant?conversation_id=${conversationId}`"
					class="mt-2 flex flex-row items-center gap-2 rounded-md px-4 py-2 text-base font-semibold text-primary transition-colors hover:bg-gray-100"
					@click="
						emits('close');
						getPosthog().capture('content-search-mode-continue-conversation', {});
					">
					<span> Continue Conversation </span>
					<ArrowRightIcon class="h-4 w-4" />
				</nuxt-link>
			</div>
		</div>
		<div v-else-if="isSending">
			<div class="p-4">
				<assistant-loading-animation />
			</div>
		</div>
		<div v-else>
			<h1 class="mt-2 text-lg font-medium">Ask me anything! You can also continue the conversation in the assistant.</h1>
		</div>
	</div>
</template>
<script lang="ts" setup>
import {
	AIMessageRating,
	IConversationMessageAI,
	IConversationMessageUser,
	UserConversationMessageSenderType,
	UserConversationMessageStatus,
	UserConversationType,
} from "~/utils/conversation";
import ObjectID from "bson-objectid";
import { ArrowRightIcon, BotIcon } from "lucide-vue-next";

const DELAY = 700;

const emits = defineEmits(["close", "mount"]);

const props = defineProps<{
	search: string;
	focusedBtn: string | null;
}>();

const response = ref<string | null>(null);
const { createConversation, pastConversations } = useConversation();
const { user } = useAuth();
const { selectedLanguage } = useLanguage();
const { settings } = useAIChat();
const conversationId = ref<string | null>(null);

const isSending = ref(false);

let needsToSearch = false;
let lastTypeMs = Date.now();
let interval: NodeJS.Timeout | null = null;

watch(
	() => props.search,
	(value) => {
		if (value) {
			needsToSearch = true;
			lastTypeMs = Date.now();
		}
	},
);

onMounted(() => {
	if (props.search) {
		processRequest();
		needsToSearch = false;
	}

	interval = setInterval(() => {
		if (needsToSearch && Date.now() - lastTypeMs > DELAY) {
			processRequest();
			needsToSearch = false;
		}
	}, 1);

	getPosthog().capture("content-search-mode-message-opened", {});
});

onUnmounted(() => {
	if (interval) clearInterval(interval);
});

async function processRequest() {
	if (!props.search) return;
	if (isSending.value) return;

	isSending.value = true;

	try {
		const conversation = await createConversation(user.value!!._id, ConversationLinkedModelType.user, UserConversationType.global_scope_chat);
		conversationId.value = conversation!!._id;
		pastConversations.value.push(conversation!!);
	} catch (e) {
		console.error(e);
		response.value = "An error occurred, try again later.";
		isSending.value = false;
		return;
	}

	if (!conversationId.value) {
		response.value = "An error occurred, try again later.";
		isSending.value = false;
		return;
	}

	const message = {
		role: "user",
		content: props.search,
	};

	const userMessage: IConversationMessageUser = {
		_id: ObjectID().toHexString(),
		user_id: user.value!!._id,

		conversation_id: conversationId.value,
		conversation_type: UserConversationType.global_scope_chat,

		message_text: message.content,
		created_at: new Date(),

		sender: {
			type: UserConversationMessageSenderType.user,
			sender_id: user.value!!._id,
		},
	};

	const assistantMessage: IConversationMessageAI = {
		generation_status: UserConversationMessageStatus.finished,
		model_information: {
			create_end_time: new Date(),
			create_start_time: new Date(),
			id: "",
			time_taken: 0,
			total_input_tokens: 0,
			total_output_tokens: 0,
		},
		rating: {
			rated_at: new Date(),
			rating: AIMessageRating.no_rating,
		},
		_id: ObjectID().toHexString(),
		user_id: user.value!!._id,

		conversation_id: conversationId.value,
		conversation_type: UserConversationType.global_scope_chat,

		message_text: "",
		created_at: new Date(),

		sender: {
			type: UserConversationMessageSenderType.assistant,
			sender_id: user.value!!._id,
		},
	};

	getPosthog().capture("content-search-mode-message-sent", {});
	response.value = "";

	try {
		await streamPOST(`/conversation/stream`, {
			data: {
				conversation_id: conversationId.value,
				messages: [message],
				userMessageId: userMessage._id,
				assistantMessageId: assistantMessage._id,
				userMessageTime: userMessage.created_at,

				language: selectedLanguage.value,

				plan: process.env.NODE_ENV === "production" ? getCurrentActivePlan() : "ULTIMATE",
				settings: settings.value,
			},
			handler: (data: string) => {
				const split = data.split("!!{{++}}!!");

				for (const str of split) {
					if (!str) continue;

					try {
						const data = JSON.parse(str);

						if (data.type === "message") {
							response.value += data.data;
						}
					} catch (e) {
						console.error(e);
					}
				}
			},
		});
	} catch (e) {
		console.error(e);
		response.value = "An error occurred, try again later.";
	} finally {
		isSending.value = false;
	}
}
</script>
