import type { Page } from "~/utils/page";

export const usePagesDrag = () => {
	const dragTarget = useState<Page | null>("drag_target", () => null);
	const isDragOverFavorite = useState<boolean>("is_drag_over_favorite", () => false);

	return {
		dragTarget,
		isDragOverFavorite,
	};
};
