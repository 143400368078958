export default defineNuxtPlugin(async () => {
	// const { pages, preloadComponents, haveComponentsBeenFetched } = usePages();
	// const isFetching = ref(false);
	// let interval = setInterval(async () => {
	// 	if (isFetching.value) return;
	// 	let notArchived = pages.value
	// 		.filter((page) => !page.archived)
	// 		.sort((a, b) => new Date(b.lastUpdated.toString()).getTime() - new Date(a.lastUpdated.toString()).getTime());
	// 	for (const page of notArchived) {
	// 		const fetchedComponents = haveComponentsBeenFetched(page._id);
	// 		isFetching.value = true;
	//
	// 		if (!fetchedComponents.value) {
	// 			await preloadComponents(page);
	// 			console.log(`Background fetch for page's components: ${page._id}, ${page.title}`);
	// 			isFetching.value = false;
	// 			break;
	// 		}
	// 	}
	//
	// 	if (notArchived.length === 0) {
	// 		clearInterval(interval);
	// 	}
	// }, 1000 * 60); // 1 minute
});
