export type Language = {
	emoji: string;
	name: string;
	aliases: string[];
};

export function matchLanguages(query: string) {
	if (!query) return languages;

	const queryLower = query.toLowerCase();
	return languages.filter((language) => language.name.toLowerCase().includes(queryLower) || language.aliases.some((alias) => alias.includes(queryLower)));
}

export const languages: Language[] = [
	{ emoji: "🇺🇸", name: "English", aliases: ["en"] },
	{ emoji: "🇨🇳", name: "Chinese", aliases: ["zh"] },
	{ emoji: "🇪🇸", name: "Spanish", aliases: ["es"] },
	{ emoji: "🇩🇪", name: "German", aliases: ["de"] },
	{ emoji: "🇫🇷", name: "French", aliases: ["fr"] },
	{ emoji: "🇷🇺", name: "Russian", aliases: ["ru"] },
	{ emoji: "🇵🇹", name: "Portuguese", aliases: ["pt"] },
	{ emoji: "🇯🇵", name: "Japanese", aliases: ["ja"] },
	{ emoji: "🇮🇹", name: "Italian", aliases: ["it"] },
	{ emoji: "🇳🇱", name: "Dutch", aliases: ["nl"] },
	{ emoji: "🇰🇷", name: "Korean", aliases: ["ko"] },
	{ emoji: "🇹🇷", name: "Turkish", aliases: ["tr"] },
	{ emoji: "🇸🇦", name: "Arabic", aliases: ["ar"] },
	{ emoji: "🇻🇳", name: "Vietnamese", aliases: ["vi"] },
	{ emoji: "🇮🇩", name: "Indonesian", aliases: ["id"] },
	{ emoji: "🇸🇪", name: "Swedish", aliases: ["sv"] },
	{ emoji: "🇵🇱", name: "Polish", aliases: ["pl"] },
	{ emoji: "🇺🇦", name: "Ukrainian", aliases: ["uk"] },
	{ emoji: "🇨🇿", name: "Czech", aliases: ["cs"] },
	{ emoji: "🇷🇴", name: "Romanian", aliases: ["ro"] },
	{ emoji: "🇬🇷", name: "Greek", aliases: ["el"] },
	{ emoji: "🇭🇺", name: "Hungarian", aliases: ["hu"] },
	{ emoji: "🇫🇮", name: "Finnish", aliases: ["fi"] },
	{ emoji: "🇩🇰", name: "Danish", aliases: ["da"] },
	{ emoji: "🇳🇴", name: "Norwegian", aliases: ["no"] },
	{ emoji: "🇹🇭", name: "Thai", aliases: ["th"] },
	{ emoji: "🇮🇷", name: "Persian", aliases: ["fa"] },
	{ emoji: "🇮🇱", name: "Hebrew", aliases: ["he"] },
	{ emoji: "🇹🇼", name: "Taiwanese", aliases: ["zh-TW"] },
	{ emoji: "🇰🇿", name: "Kazakh", aliases: ["kk"] },
	{ emoji: "🇬🇪", name: "Georgian", aliases: ["ka"] },
	{ emoji: "🇧🇷", name: "Brazilian Portuguese", aliases: ["pt-BR"] },
	{ emoji: "🇰🇿", name: "Kazakh", aliases: ["kk"] },
	{ emoji: "🇮🇳", name: "Hindi", aliases: ["hi"] },
	{ emoji: "🇸🇮", name: "Slovenian", aliases: ["sl"] },
	{ emoji: "🇷🇸", name: "Serbian", aliases: ["sr"] },
	{ emoji: "🇭🇷", name: "Croatian", aliases: ["hr"] },
	{ emoji: "🇧🇪", name: "Dutch (Belgium)", aliases: ["nl-BE"] },
	{ emoji: "🇧🇬", name: "Bulgarian", aliases: ["bg"] },
	{ emoji: "🇦🇹", name: "Austrian German", aliases: ["de-AT"] },
	{ emoji: "🇨🇦", name: "Canadian French", aliases: ["fr-CA"] },
	{ emoji: "🇨🇭", name: "Swiss German", aliases: ["de-CH"] },
	{ emoji: "🇸🇪", name: "Swedish", aliases: ["sv-SE"] },
	{ emoji: "🇱🇹", name: "Lithuanian", aliases: ["lt"] },
	{ emoji: "🇪🇪", name: "Estonian", aliases: ["et"] },
	{ emoji: "🇩🇯", name: "Dzongkha", aliases: ["dz"] },
	{ emoji: "🇳🇵", name: "Nepali", aliases: ["ne"] },
	{ emoji: "🇱🇺", name: "Luxembourgish", aliases: ["lb"] },
	{ emoji: "🇱🇻", name: "Latvian", aliases: ["lv"] },
	{ emoji: "🇸🇰", name: "Slovak", aliases: ["sk"] },
	{ emoji: "🇲🇾", name: "Malay", aliases: ["ms"] },
	{ emoji: "🇵🇰", name: "Urdu", aliases: ["ur"] },
	{ emoji: "🇩🇯", name: "Dzongkha", aliases: ["dz"] },
	{ emoji: "🇧🇾", name: "Belarusian", aliases: ["be"] },
	{ emoji: "🇨🇴", name: "Spanish (Colombia)", aliases: ["es-CO"] },
	{ emoji: "🇬🇹", name: "Spanish (Guatemala)", aliases: ["es-GT"] },
	{ emoji: "🇭🇳", name: "Spanish (Honduras)", aliases: ["es-HN"] },
	{ emoji: "🇲🇽", name: "Spanish (Mexico)", aliases: ["es-MX"] },
	{ emoji: "🇵🇾", name: "Spanish (Paraguay)", aliases: ["es-PY"] },
	{ emoji: "🇵🇪", name: "Spanish (Peru)", aliases: ["es-PE"] },
	{ emoji: "🇵🇷", name: "Spanish (Puerto Rico)", aliases: ["es-PR"] },
	{ emoji: "🇺🇾", name: "Spanish (Uruguay)", aliases: ["es-UY"] },
	{ emoji: "🇻🇪", name: "Spanish (Venezuela)", aliases: ["es-VE"] },
	{ emoji: "🇦🇷", name: "Spanish (Argentina)", aliases: ["es-AR"] },
	{ emoji: "🇨🇱", name: "Spanish (Chile)", aliases: ["es-CL"] },
	{ emoji: "🇨🇷", name: "Spanish (Costa Rica)", aliases: ["es-CR"] },
	{ emoji: "🇩🇴", name: "Spanish (Dominican Republic)", aliases: ["es-DO"] },
	{ emoji: "🇪🇨", name: "Spanish (Ecuador)", aliases: ["es-EC"] },
	{ emoji: "🇸🇻", name: "Spanish (El Salvador)", aliases: ["es-SV"] },
	{ emoji: "🇬🇶", name: "Spanish (Equatorial Guinea)", aliases: ["es-GQ"] },
	{ emoji: "🇪🇸", name: "Spanish (Spain)", aliases: ["es-ES"] },
	{ emoji: "🇵🇱", name: "Polish", aliases: ["pl"] },
	{ emoji: "🇷🇴", name: "Romanian", aliases: ["ro"] },
	{ emoji: "🇺🇦", name: "Ukrainian", aliases: ["uk"] },
	{ emoji: "🇨🇿", name: "Czech", aliases: ["cs"] },
	{ emoji: "🇷🇸", name: "Serbian", aliases: ["sr"] },
	{ emoji: "🇬🇷", name: "Greek", aliases: ["el"] },
	{ emoji: "🇭🇺", name: "Hungarian", aliases: ["hu"] },
	{ emoji: "🇫🇮", name: "Finnish", aliases: ["fi"] },
	{ emoji: "🇩🇰", name: "Danish", aliases: ["da"] },
	{ emoji: "🇳🇴", name: "Norwegian", aliases: ["no"] },
	{ emoji: "🇸🇪", name: "Swedish", aliases: ["sv"] },
	{ emoji: "🇹🇭", name: "Thai", aliases: ["th"] },
	{ emoji: "🇮🇷", name: "Persian", aliases: ["fa"] },
	{ emoji: "🇮🇱", name: "Hebrew", aliases: ["he"] },
	{ emoji: "🇹🇼", name: "Taiwanese", aliases: ["zh-TW"] },
	{ emoji: "🇰🇿", name: "Kazakh", aliases: ["kk"] },
	{ emoji: "🇬🇪", name: "Georgian", aliases: ["ka"] },
	{ emoji: "🇧🇷", name: "Brazilian Portuguese", aliases: ["pt-BR"] },
	{ emoji: "🇰🇿", name: "Kazakh", aliases: ["kk"] },
	{ emoji: "🇮🇳", name: "Hindi", aliases: ["hi"] },
	{ emoji: "🇸🇮", name: "Slovenian", aliases: ["sl"] },
	{ emoji: "🇭🇷", name: "Croatian", aliases: ["hr"] },
	{ emoji: "🇧🇪", name: "Dutch (Belgium)", aliases: ["nl-BE"] },
	{ emoji: "🇧🇬", name: "Bulgarian", aliases: ["bg"] },
	{ emoji: "🇦🇹", name: "Austrian German", aliases: ["de-AT"] },
	{ emoji: "🇨🇦", name: "Canadian French", aliases: ["fr-CA"] },
	{ emoji: "🇨🇭", name: "Swiss German", aliases: ["de-CH"] },
	{ emoji: "🇸🇪", name: "Swedish", aliases: ["sv-SE"] },
	{ emoji: "🇱🇹", name: "Lithuanian", aliases: ["lt"] },
	{ emoji: "🇪🇪", name: "Estonian", aliases: ["et"] },
	{ emoji: "🇩🇯", name: "Dzongkha", aliases: ["dz"] },
	{ emoji: "🇳🇵", name: "Nepali", aliases: ["ne"] },
	{ emoji: "🇱🇺", name: "Luxembourgish", aliases: ["lb"] },
	{ emoji: "🇱🇻", name: "Latvian", aliases: ["lv"] },
	{ emoji: "🇸🇰", name: "Slovak", aliases: ["sk"] },
	{ emoji: "🇲🇾", name: "Malay", aliases: ["ms"] },
	{ emoji: "🇵🇰", name: "Urdu", aliases: ["ur"] },
	{ emoji: "🇧🇾", name: "Belarusian", aliases: ["be"] },
	{ emoji: "🇵🇪", name: "Spanish (Peru)", aliases: ["es-PE"] },
	{ emoji: "🇵🇷", name: "Spanish (Puerto Rico)", aliases: ["es-PR"] },
	{ emoji: "🇺🇾", name: "Spanish (Uruguay)", aliases: ["es-UY"] },
	{ emoji: "🇻🇪", name: "Spanish (Venezuela)", aliases: ["es-VE"] },
	{ emoji: "🇦🇷", name: "Spanish (Argentina)", aliases: ["es-AR"] },
	{ emoji: "🇨🇱", name: "Spanish (Chile)", aliases: ["es-CL"] },
	{ emoji: "🇨🇷", name: "Spanish (Costa Rica)", aliases: ["es-CR"] },
	{ emoji: "🇩🇴", name: "Spanish (Dominican Republic)", aliases: ["es-DO"] },
	{ emoji: "🇪🇨", name: "Spanish (Ecuador)", aliases: ["es-EC"] },
	{ emoji: "🇸🇻", name: "Spanish (El Salvador)", aliases: ["es-SV"] },
	{ emoji: "🇬🇶", name: "Spanish (Equatorial Guinea)", aliases: ["es-GQ"] },
	{ emoji: "🇪🇸", name: "Spanish (Spain)", aliases: ["es-ES"] },
	{ emoji: "🇲🇽", name: "Spanish (Mexico)", aliases: ["es-MX"] },
	{ emoji: "🇵🇾", name: "Spanish (Paraguay)", aliases: ["es-PY"] },
	{ emoji: "🇨🇴", name: "Spanish (Colombia)", aliases: ["es-CO"] },
	{ emoji: "🇬🇹", name: "Spanish (Guatemala)", aliases: ["es-GT"] },
	{ emoji: "🇭🇳", name: "Spanish (Honduras)", aliases: ["es-HN"] },
	{ emoji: "🇳🇱", name: "Dutch", aliases: ["nl"] },
	{ emoji: "🇨🇳", name: "Chinese", aliases: ["zh"] },
	{ emoji: "🇺🇸", name: "English", aliases: ["en"] },
	{ emoji: "🇩🇪", name: "German", aliases: ["de"] },
	{ emoji: "🇫🇷", name: "French", aliases: ["fr"] },
	{ emoji: "🇷🇺", name: "Russian", aliases: ["ru"] },
	{ emoji: "🇵🇹", name: "Portuguese", aliases: ["pt"] },
	{ emoji: "🇯🇵", name: "Japanese", aliases: ["ja"] },
	{ emoji: "🇮🇹", name: "Italian", aliases: ["it"] },
	{ emoji: "🇰🇷", name: "Korean", aliases: ["ko"] },
	{ emoji: "🇹🇷", name: "Turkish", aliases: ["tr"] },
	{ emoji: "🇸🇦", name: "Arabic", aliases: ["ar"] },
	{ emoji: "🇻🇳", name: "Vietnamese", aliases: ["vi"] },
	{ emoji: "🇮🇩", name: "Indonesian", aliases: ["id"] },
];
