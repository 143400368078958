<template>
	<div class="rounded-md border border-red-200 bg-red-50/40 px-4 py-4">
		<div class="flex flex-row items-center gap-2 text-red-800">
			<div>
				<ExclamationTriangleIcon class="h-4 w-4" />
			</div>
			<div class="text-lg">
				<slot />
			</div>
		</div>
		<button
			class="mt-2 flex flex-row items-center space-x-2 rounded-md px-3 py-1 text-base font-semibold text-red-800 hover:bg-red-100"
			@click="feedbackModalOpen = true">
			<span> Report a problem </span>
			<animated-chevron-icon
				:mode="'right'"
				class="h-4 w-4" />
		</button>
	</div>
</template>
<script lang="ts" setup>
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";

const { feedbackModalOpen } = useFeedback();
</script>
