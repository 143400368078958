<template>
	<teleport to="body">
		<modal
			:exit="false"
			title="Archive Unused Pages"
			@close="$emit('close')">
			<div class="w-[700px]">
				<p class="text-base text-gray-500">
					Scholarly Assistant determined that the following pages can be archived due to inactivity, or because they are no longer relevant to your work.
				</p>
				<div
					v-if="!loaded && !error"
					class="mt-4">
					<spin class="h-6 w-6" />
				</div>
				<error-container
					v-if="error"
					class="mt-4">
					{{ error }}
				</error-container>
				<div
					v-if="loaded && !error"
					class="mt-4">
					<div class="grid grid-cols-2 gap-2">
						<div
							v-for="(page, index) in unusedPages"
							:key="page._id"
							class="relative rounded-md border border-gray-150 bg-white px-4 py-2 transition-colors">
							<div class="flex flex-row items-start justify-between">
								<h1 class="text-base font-semibold">
									{{ page.title ? page.title : "Untitled Page" }}
								</h1>
								<button
									class="text-red-400 transition-colors hover:text-red-800"
									@click.prevent="unusedPagePayload.unused_pages.splice(index, 1)">
									<XIcon class="h-4 w-4" />
								</button>
							</div>
							<div class="flex flex-row items-center justify-between">
								<span class="text-sm text-gray-600">
									Created <strong class="font-medium">{{ mdy(page.createdAt.toString()) }}</strong>
								</span>
								<span class="text-sm text-gray-500">
									Last used <strong class="font-medium">{{ timeAgoPretty(page.lastOpened.toString()) }}</strong>
								</span>
							</div>
						</div>
					</div>
					<div
						v-if="unusedPages.length > 0"
						class="mt-4 flex flex-row items-center space-x-4">
						<button
							class="flex items-center gap-2 rounded-md bg-secondary px-8 py-1 text-sm font-medium text-white transition-colors hover:bg-primary"
							@click="archiveThem">
							<CheckIcon class="h-4 w-4" />
							<span> Yes </span>
						</button>
						<button
							class="flex items-center space-x-2 rounded-md border border-gray-200 px-8 py-1 text-sm font-bold text-gray-700 shadow-sm transition-colors hover:bg-gray-100"
							@click="$emit('close')">
							<XMarkIcon class="h-4 w-4" />
							<span> No </span>
						</button>
					</div>
					<div
						v-else
						class="mt-4 flex flex-col items-center space-x-4">
						<h1 class="text-xl font-semibold text-primary">You're already clean!</h1>
						<p>We couldn't find any pages that need to be archived.</p>

						<div class="mt-4 flex flex-row items-center justify-center gap-4">
							<button
								@click.prevent="$emit('close')"
								class="flex items-center gap-2 rounded-md bg-secondary px-8 py-1 text-sm font-medium text-white transition-colors hover:bg-primary">
								Close
							</button>
							<button
								@click.prevent="tryAgainButton()"
								data-no-close-popup="true"
								class="flex items-center gap-2 rounded-md border border-gray-150 px-8 py-1 text-sm font-medium text-gray-700 transition-colors hover:bg-gray-100">
								Try again
							</button>
						</div>
					</div>
				</div>
			</div>
		</modal>
	</teleport>
</template>
<script lang="ts" setup>
import { CheckIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import axios from "axios";
import { XIcon } from "lucide-vue-next";
import { PageSyncPipeline } from "~/utils/sync/page_sync_pipeline";
import syncPageUpdate = PageSyncPipeline.syncPageUpdate;

const emits = defineEmits(["close"]);
const unusedPagePayload = useState<{
	unused_pages: string[]; // page ids
	reason: string;
}>("unused_pages_cleanup_modal_unused_pages", () => {
	return {
		unused_pages: [],
		reason: "",
	};
});
const loaded = useState<boolean>("unused_pages_cleanup_modal_loaded", () => false);
const error = useState<string>("unused_pages_cleanup_modal_error", () => "");
const { pages } = usePages();

onMounted(async () => {
	if (!loaded.value) {
		await calculateUnusedPages();
		loaded.value = true;
	}

	getPosthog().capture("unused_pages_cleanup_modal_opened", {});
});

const unusedPages = computed(() => unusedPagePayload.value.unused_pages.map((id) => pages.value.find((p) => p._id === id)!!));

function tryAgainButton() {
	calculateUnusedPages();

	getPosthog().capture("unused_pages_cleanup_modal_try_again", {});
}

async function calculateUnusedPages() {
	loaded.value = false;
	unusedPagePayload.value = {
		unused_pages: [],
		reason: "",
	};
	error.value = "";
	try {
		const { data } = await axios.get("/page/propose_page_archive");
		unusedPagePayload.value = data;

		getPosthog().capture("unused_pages_cleanup_modal_completed", {
			count: unusedPagePayload.value.unused_pages.length,
		});
	} catch (e) {
		console.error(e);
		error.value = "Failed to fetch find pages to cleanup.";
	} finally {
		loaded.value = true;
	}
}

function archiveThem() {
	let i = 0;
	for (const page of unusedPages.value) {
		page.archived = true;
		page.archivedAt = new Date();

		syncPageUpdate(page);
		i++;
	}

	addAlert("Archived " + i + " pages.", "success");
	emits("close");
}
</script>
