<template>
	<Transition name="fade">
		<slot></slot>
	</Transition>
</template>
<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 100ms ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
