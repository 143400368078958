import { getCurrentActivePlan, Plan } from "~/utils/user";
import { ULTIMATE_PLAN } from "~/utils/premium_plans";

const MIN_WIDTH_TO_CLOSE_SIDEBAR_FOR_CONVERSATION = 1280;

//todo; merge this composable with useConversation (get rid of this one)

interface ConversationSettings {
	youtube_search_disabled: boolean;
	suggestions_disabled: boolean;

	autoPlayAudio: boolean; //default is probably always undefined
}

export const useAIChat = () => {
	const accruedLimit = useCookie<number>("accruedLimit", {
		expires: getTonightAtMidnight(),
	});
	const conversationOpen = useBrowserState("conversationOpen", false);

	const settings = useBrowserState<ConversationSettings>("conversationSettings", {
		youtube_search_disabled: false,
		suggestions_disabled: false,
		autoPlayAudio: false,
	});

	if (accruedLimit.value === undefined) {
		accruedLimit.value = 0;
	}

	function getTonightAtMidnight() {
		const now = new Date();
		return new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
	}

	function hasReachedDailyLimit() {
		return accruedLimit.value >= getDailyLimit();
	}

	function toggleConversation() {
		conversationOpen.value = !conversationOpen.value;
	}

	function getDailyLimit() {
		const plan = getCurrentActivePlan();

		if (!plan) {
			return 0;
		}

		if (plan === Plan.FREE) {
			return FREE_PLAN.assistant_messages;
		} else if (plan === Plan.PLUS) {
			return PRO_PLAN.assistant_messages;
		} else if (plan === Plan.ULTIMATE) {
			return ULTIMATE_PLAN.assistant_messages;
		}

		return 0;
	}

	return {
		accruedLimit,
		hasReachedDailyLimit,
		getDailyLimit,
		getTonightAtMidnight,
		toggleConversation,
		conversationOpen,
		settings,
	};
};
