import axios from "axios";

export interface IFavoriteModel {
	_id: string;
	userId: string;

	itemId: string;
	itemType: "page" | "component";
	createdAt: Date;

	sortIndex: number;
}

export type PopulatedFavorite = IFavoriteModel & {
	data: any;
};

export const useFavorites = () => {
	const favorites = useState<PopulatedFavorite[]>("user_favorites", () => []);

	async function addFavorite(itemId: string, type: "component" | "page", item: any, pageId?: string) {
		if (isFavorite(itemId)) {
			console.error("Item is already a favorite");
			return;
		}
		const fav: PopulatedFavorite = {
			_id: "",
			userId: "",
			itemId: itemId,
			itemType: type,
			createdAt: new Date(),
			sortIndex: 0,
			data: {},
		};

		if (type === "component") {
			const component = item;

			if (component.type == ComponentType.IMAGE) {
				fav.data = {
					componentType: component.type,
					name: component.content.file.name,
				};
			} else if (component.type === ComponentType.FLASH_CARDS) {
				fav.data = {
					componentType: component.type,
					title: item.content.title,
					pageId: pageId,
				};
			}
		}

		if (type === "page") {
			fav.data = {
				title: item.title,
				emoji: item.emoji,
			};
		}

		favorites.value.push(fav);
		axios
			.post("/favorite/add", {
				itemId: itemId,
				itemType: type,
			})
			.catch((e) => {
				favorites.value = favorites.value.filter((favorite) => favorite.itemId !== itemId);
				console.error(e);
				addAlert("Failed to add favorite, please try again", "error");
			});
	}

	async function fetchFavorites() {
		const { data } = await axios.get("/favorite/get");

		favorites.value = data.favorites;
	}

	function isFavorite(itemId: string) {
		return favorites.value.some((favorite) => favorite.itemId === itemId);
	}

	async function removeFavorite(itemId: string) {
		if (!isFavorite(itemId)) {
			console.error("Item is not a favorite");
			return;
		}
		favorites.value = favorites.value.filter((favorite) => favorite.itemId !== itemId);

		try {
			await axios.post("/favorite/remove", {
				itemId: itemId,
			});
		} catch (e) {
			console.error(e);
			addAlert("Failed to remove favorite, please try again", "error");
			favorites.value = favorites.value.filter((favorite) => favorite.itemId !== itemId);
		}
	}

	return {
		favorites,
		addFavorite,
		removeFavorite,
		isFavorite,
		fetchFavorites,
	};
};
