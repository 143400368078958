<template>
	<ChevronUpIcon :style="style" />
</template>
<script lang="ts" setup>
import { ChevronUpIcon } from "@heroicons/vue/24/solid";

const props = defineProps<{
	mode: "up" | "down" | "left" | "right";
}>();

const style = computed(() => {
	return {
		transform: `rotate(${props.mode === "down" ? 180 : props.mode === "up" ? 0 : props.mode === "right" ? 90 : -90}deg)`,
	};
});
</script>
<style scoped>
svg {
	transition: transform 0.2s;
}
</style>
