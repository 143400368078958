import { useLocalStorage } from "@vueuse/core";

type UserPreferences = {
	sidebar: {
		minimized: boolean;

		favorites_minimized: boolean;
		recents_minimized: boolean;
		pages_minimized: boolean;
	};

	flashcards: {
		bucket: "all" | "favorites";
	};

	conversation: {
		max_words: number;
	};
};

export const useUserPreferences = () => {
	const preferences = useLocalStorage<UserPreferences>(
		"user_preferences",
		{
			sidebar: {
				minimized: false,
				favorites_minimized: false,
				pages_minimized: false,
				recents_minimized: true,
			},
			flashcards: {
				bucket: "all",
			},
			conversation: {
				max_words: 150,
			},
		},
		{
			deep: true,
			listenToStorageChanges: true,
			writeDefaults: true,
			mergeDefaults: true,
		},
	);

	return {
		preferences,
	};
};
