export const usePlanModal = () => {
	const planModalOpen = useState<boolean>("planModal", () => false);

	const openPlanModal = () => {
		planModalOpen.value = true;
	};

	const closePlanModal = () => {
		planModalOpen.value = false;
	};

	const togglePlanModal = () => {
		planModalOpen.value = !planModalOpen.value;
	};

	return {
		planModalOpen,
		openPlanModal,
		closePlanModal,
		togglePlanModal,
	};
};
