import type { Page } from "~/utils/page";

type RecordedAction = {
	name: string;
	undo: () => void;
	redo: () => void;
};

const undoStack = new Map<string, RecordedAction[]>();
const redoStack = new Map<string, RecordedAction[]>();

export function recordPageAction(page: Page | string, action: RecordedAction) {
	push(getPageId(page), action, undoStack);
}

export function undoRecent(page: Page | string) {
	const latestAction = undoStack.get(getPageId(page))?.pop();

	if (!latestAction) return;

	latestAction.undo();
	push(getPageId(page), latestAction, redoStack);
}

export function redoRecent(page: Page | string) {
	const latestAction = redoStack.get(getPageId(page))?.pop();

	if (!latestAction) return;

	latestAction.redo();
	push(getPageId(page), latestAction, undoStack);
}

function getPageId(page: Page | string) {
	return typeof page === "string" ? page.toString() : (page as Page)._id!!;
}

function push(id: string, toPush: RecordedAction, map: Map<string, RecordedAction[]>) {
	if (!map.has(id)) {
		map.set(id, []);
	}

	const a = map.get(id)!!;

	a.push(toPush);
}
