<template>
	<div>
		<button
			:class="{ 'scholarly-button-normal-dark': dark }"
			class="scholarly-button-normal"
			@click.prevent="dropdownOpen = !dropdownOpen">
			<span> Language: {{ selectedLanguage }} </span>
			<animated-chevron-icon
				:mode="dropdownOpen ? 'up' : 'down'"
				class="h-4 w-4" />
		</button>
		<menu-transition>
			<dropdown
				v-if="dropdownOpen"
				:dark="dark"
				class="mt-1"
				width="250px">
				<div
					:class="{ 'styled-input-with-icon-dark': dark }"
					class="styled-input-with-icon-with-dark">
					<input
						v-model="search"
						placeholder="Search.."
						type="text" />
					<MagnifyingGlassIcon class="h-4 w-4" />
				</div>
				<div class="mt-2 h-64 overflow-y-auto">
					<!--					TODO: This is quite slow, we need to optimize it somehow... -->
					<dropdown-item
						v-for="language in getLanguages"
						:key="language.name"
						:dark="dark"
						:icon="language.emoji"
						@click="selectLanguage(language)">
						{{ language.name }}
					</dropdown-item>
				</div>
			</dropdown>
		</menu-transition>
	</div>
</template>
<script lang="ts" setup>
import { Language, matchLanguages } from "~/utils/language";
import { MagnifyingGlassIcon } from "@heroicons/vue/24/solid";
import { useLanguage } from "~/composables/useLanguage";

const props = defineProps<{
	dark?: boolean;
}>();
const { selectedLanguage } = useLanguage();
const dropdownOpen = ref(false);

if (!selectedLanguage.value) {
	selectedLanguage.value = "English";
}

const search = ref("");

const getLanguages = computed(() => {
	return matchLanguages(search.value);
});

function selectLanguage(language: Language) {
	selectedLanguage.value = language.name;
	dropdownOpen.value = false;
}
</script>
