export default defineNuxtPlugin(async () => {
	const { user } = useAuth();

	useLocalizedPrices()
		.fetchLocalizedPrices()
		.then((r) => {});

	if (!user.value) return;

	let promises: Promise<any>[] = [];
	promises.push(useFlashcardFavorites().loadFavorites());
	promises.push(useFolders().fetch());
	promises.push(useHomeData().fetchChartData());
	promises.push(useConversation().fetchPastConversations());

	Promise.allSettled(promises).then(() => {});
});
