<template>
	<div
		v-if="!user"
		class="flex min-h-screen w-full flex-col items-center pt-6 sm:justify-center sm:pt-0">
		<div class="flex flex-row items-center space-x-3">
			<ExclamationCircleIcon class="h-8 w-8 text-black" />
			<div>
				<p class="font-medium">
					{{ error.statusCode === 404 ? "Page not found" : error.message }}
				</p>
				<p class="text-sm text-gray-500">
					{{ error.statusCode }}
				</p>
			</div>
		</div>
		<div class="mt-4">
			<nuxt-link
				class="rounded-md border border-gray-200 px-3 py-1 text-sm font-medium text-gray-700 shadow-sm transition-colors hover:bg-gray-100"
				to="/">
				Go back home
			</nuxt-link>
		</div>
	</div>
	<default v-if="user">
		<div class="inset-center-xy absolute flex flex-col items-center justify-center">
			<div class="w-full">
				<div class="flex flex-row items-center space-x-3">
					<ExclamationCircleIcon class="h-8 w-8 text-black" />
					<div>
						<p class="font-medium">
							{{ error ? error.message : "Unknown Error" }}
						</p>
						<p class="text-sm text-gray-500">
							{{ error ? error.statusCode : "Unknown Status Code" }}
						</p>
					</div>
				</div>
				<div class="mt-4 flex flex-row items-center gap-2">
					<nuxt-link
						class="rounded-md bg-secondary px-3 py-1 text-sm font-medium text-white transition-colors hover:bg-primary"
						to="/">
						Go back home
					</nuxt-link>
					<button
						class="rounded-md border border-gray-200 px-3 py-1 text-sm font-medium text-gray-700 shadow-sm transition-colors hover:bg-gray-100"
						@click="feedbackModalOpen = true">
						Report bug
					</button>
				</div>
			</div>
		</div>
	</default>
</template>
<script lang="ts" setup>
import Default from "~/layouts/default.vue";
import { ExclamationCircleIcon } from "@heroicons/vue/24/solid";

defineProps({
	error: Object,
});

definePageMeta({
	title: "Error",
});

const { feedbackModalOpen } = useFeedback();
const error = useError();
const { user } = useAuth();

onMounted(() => {
	getPosthog().capture("error_page_view", {
		error: error.value?.statusCode,
	});
});
</script>
