export function getPosthog() {
	if (import.meta.env.MODE === "development") {
		return {
			capture: (str: string, any: any) => {
				// console.log(`Captured posthog custom event: ${str}`);
			},
		};
	}
	const { $posthog } = useNuxtApp();
	const posthog = $posthog();

	return posthog!!;
}
