import type { Page } from "~/utils/page";
import { Component } from "~//utils/page";

export class ModelUpdatePipeline<T> {
	pendingTransactions: Map<string, Array<() => void>> = new Map<string, Array<() => void>>();

	awaitTransaction(modelId: string, transaction: () => void) {
		if (!this.pendingTransactions.has(modelId)) {
			transaction();
			return;
		}

		this.addTransaction(modelId, transaction);
	}

	markComplete(modelId: string) {
		const transactions = this.pendingTransactions.get(modelId);

		if (!transactions) {
			return;
		}

		for (const transaction of transactions) {
			transaction();
		}

		this.pendingTransactions.delete(modelId);
	}

	markPending(modelId: string) {
		this.pendingTransactions.set(modelId, []);
	}

	private addTransaction(modelId: string, transaction: () => void) {
		const transactions = this.pendingTransactions.get(modelId);

		if (transactions) {
			transactions.push(transaction);
		} else {
			this.pendingTransactions.set(modelId, [transaction]);
		}
	}
}

export const pageUpdatePipeline = new ModelUpdatePipeline<Page>();
export const componentUpdatePipeline = new ModelUpdatePipeline<Component>();
