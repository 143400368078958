<template>
	<div
		v-if="searchMode !== 'message'"
		:class="{ 'bg-gray-100': focusedBtn === 'action_ask_scholarly_assistant' }"
		:data-content-search-option-id="'action_ask_scholarly_assistant'"
		class="mt-1 flex w-full flex-row items-center gap-3 rounded-md px-4 py-2.5 hover:bg-gray-100"
		role="button"
		@click.prevent="searchMode = 'message'">
		<BotIcon class="h-5 w-5 text-secondary" />
		<div>
			<h1 class="text-base font-semibold">Talk to Scholarly Assistant</h1>
			<p class="text-xs text-gray-500">Get a quick answer from Scholarly Assistant.</p>
		</div>
	</div>
	<div
		v-if="searchMode !== 'normal'"
		:class="{ 'bg-gray-100': focusedBtn === 'switch_mode_normal' }"
		:data-content-search-option-id="'switch_mode_normal'"
		class="mt-1 flex w-full flex-row items-center gap-3 rounded-md px-4 py-2.5 hover:bg-gray-100"
		role="button"
		@click.prevent="searchMode = 'normal'">
		<UndoDot class="h-5 w-5 text-gray-600" />
		<div>
			<h1 class="text-base font-semibold">Normal Search</h1>
			<p class="text-xs text-gray-500">Search your content library with the normal search mode.</p>
		</div>
	</div>
	<div
		v-if="searchMode !== 'ai'"
		:class="{ 'bg-gray-100': focusedBtn === 'action_ai' }"
		:data-content-search-option-id="'action_ai'"
		class="mt-1 flex w-full flex-row items-center gap-3 rounded-md px-4 py-2.5 hover:bg-gray-100"
		role="button"
		@click.prevent="searchMode = 'ai'">
		<SparklesIcon class="h-5 w-5 ai-tool-color" />
		<div>
			<h1 class="text-base font-semibold">Smart AI Search</h1>
			<p class="text-xs text-gray-500">Let Scholarly Assistant search your entire content library for you, just ask it in natural language.</p>
		</div>
	</div>
	<div
		v-if="searchScope === 'personal' && searchMode === 'ai'"
		:class="{ 'bg-gray-100': focusedBtn === 'action_switch_scope' }"
		:data-content-search-option-id="'action_switch_scope'"
		class="mt-1 flex w-full flex-row items-center gap-3 rounded-md px-4 py-2.5 hover:bg-gray-100"
		role="button"
		@click.prevent="searchScope = 'global'">
		<GlobeIcon class="h-5 w-5 text-gray-500" />
		<div>
			<h1 class="text-base font-semibold">Search Globally</h1>
			<p class="text-xs text-gray-500">Find content from other Scholarly users.</p>
		</div>
	</div>
	<div
		v-else-if="searchMode === 'ai'"
		:class="{ 'bg-gray-100': focusedBtn === 'action_switch_scope' }"
		:data-content-search-option-id="'action_switch_scope'"
		class="mt-1 flex w-full flex-row items-center gap-3 rounded-md px-4 py-2.5 hover:bg-gray-100"
		role="button"
		@click.prevent="searchScope = 'personal'">
		<LibraryIcon class="h-5 w-5 text-gray-600" />
		<div>
			<h1 class="text-base font-semibold">Search Personal Content</h1>
			<p class="text-xs text-gray-500">Find content from your personal library.</p>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { BotIcon, GlobeIcon, LibraryIcon, SparklesIcon, UndoDot } from "lucide-vue-next";

const props = defineProps<{
	focusedBtn: string | null;
}>();

const { searchScope, searchMode } = useContentSearch();
</script>
