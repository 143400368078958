import axios from "axios";

export const useUserSettings = () => {
	const loaded = useState<boolean>("userSettingsLoaded", () => false);
	const settingsModalOpen = useState("settingsModalOpen", () => false);
	const userSettingsSelectedPage = useState("userSettingsSelectedPage", () => "");

	function toggleSettingsModal(page?: string) {
		if (page) {
			userSettingsSelectedPage.value = page;
		} else {
			userSettingsSelectedPage.value = "my_account";
		}
		settingsModalOpen.value = !settingsModalOpen.value;
	}

	return {
		fetch,
		loaded,
		toggleSettingsModal,
		settingsModalOpen,
		userSettingsSelectedPage,
	};
};
