export const useAdTracking = () => {
	const getUtmCampaign = useCookie("utm_campaign_scholarly", {
		maxAge: 60 * 60 * 24 * 30, //30 days
	});
	const getUtmSource = useCookie("utm_source_scholarly", {
		maxAge: 60 * 60 * 24 * 30, //30 days
	});

	return {
		getUtmCampaign,
		getUtmSource,
	};
};
