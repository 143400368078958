export default defineNuxtPlugin(async () => {
	setInterval(
		async () => {
			const { fetchUser } = useAuth();

			await fetchUser();
		},
		1000 * 60 * 60 * 23,
	); //every 23 hours, refresh token, expire after 24 hours
});
