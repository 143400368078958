export interface PopupContent {
	closeListener: () => void;
	element: HTMLElement;
	registeredMs: number;
	alreadyClosed?: boolean;
}

export const usePopupContent = () => {
	const openContent = useState<PopupContent[]>("usePopupContent.openContent", () => []);

	function registerContent(content: { closeListener: () => void; element: HTMLElement }) {
		let result: PopupContent = {
			...content,
			registeredMs: Date.now(),
		};
		openContent.value.push(result);

		return result;
	}

	function closeSpecific(content: PopupContent) {
		content.closeListener();
		const index = openContent.value.indexOf(content);

		if (index !== -1) {
			openContent.value.splice(index, 1);
		}
	}

	function destroyContent(content: PopupContent) {
		const index = openContent.value.indexOf(content);
		if (index !== -1) {
			openContent.value.splice(index, 1);
		}
	}

	return {
		registerContent,
		closeSpecific,
		openContent,
		destroyContent,
	};
};
