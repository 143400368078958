<template>
	<div class="relative w-full">
		<div class="flex w-full items-center justify-between space-x-3 rounded-lg border border-gray-150 bg-white px-3 py-1.5">
			<div class="flex flex-row items-center space-x-2">
				<img
					:src="user!!.profilePictureUrl"
					alt=""
					class="h-5 w-5 rounded-full shadow-sm"
					loading="lazy" />
				<div>
					<p class="text-xs font-medium">{{ shortenText(user!!.name, 10) }}</p>
				</div>
			</div>
			<button @click="dropdownOpen = !dropdownOpen">
				<AnimatedChevronIcon
					:mode="dropdownOpen ? 'up' : 'down'"
					class="h-4 w-4" />
			</button>
		</div>
		<menu-transition>
			<dropdown
				v-if="dropdownOpen"
				:class="{
					'right-0': props.placement === 'right',
					'left-0': props.placement === 'left',
				}"
				class="mt-1"
				width="220px"
				@close="dropdownOpen = false">
				<div class="flex w-full flex-row items-center space-x-2 rounded-[4px] border-b border-gray-100 px-2 py-1.5 text-gray-800 transition-colors hover:bg-gray-100">
					<div class="flex flex-row items-center space-x-2">
						<img
							:src="user!!.profilePictureUrl"
							alt=""
							class="h-6 w-6 rounded-full shadow-sm"
							loading="lazy" />
						<div>
							<p class="text-sm font-medium">{{ user!!.name }}</p>
							<p>
								<span class="text-xs text-gray-500">{{ user!!.email }}</span>
							</p>
						</div>
					</div>
				</div>
				<dropdown-item
					:icon="CreditCardIcon"
					:spinner="openingPortal"
					class="mt-2"
					@click="handleBilling">
					Billing
				</dropdown-item>
				<dropdown-item
					:icon="Cog6ToothIcon"
					@click="
						toggleSettingsModal();
						dropdownOpen = false;
					">
					Settings
				</dropdown-item>
				<dropdown-item
					:icon="ArrowLeftOnRectangleIcon"
					@click="navigateTo('/logout')">
					Logout
				</dropdown-item>
				<div
					v-if="organization"
					class="mt-1 border-t border-gray-200 px-2 py-2">
					<h1 class="font-semibold text-black">
						{{ organization.primary_name }}
					</h1>
				</div>
			</dropdown>
		</menu-transition>
	</div>
</template>
<script lang="ts" setup>
import { ArrowLeftOnRectangleIcon, Cog6ToothIcon, CreditCardIcon } from "@heroicons/vue/24/outline";
import axios from "axios";

const props = defineProps<{
	placement: "left" | "right";
}>();

const { user, organization } = useAuth();
const { togglePlanModal } = usePlanModal();

const { toggleSettingsModal } = useUserSettings();
const openingPortal = ref(false);
const dropdownOpen = ref(false);

async function handleBilling() {
	if (getCurrentActivePlan() === Plan.FREE) {
		togglePlanModal();
		return;
	}

	openingPortal.value = true;

	const { data } = await axios.get("/stripe/customer_portal");

	//open new window with data.url
	window.open(data.url, "_blank");

	openingPortal.value = false;
}
</script>
