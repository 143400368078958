<template>
	<nudge
		id="share-feedback"
		action="Share Feedback"
		description="Help us improve Scholarly by sharing your experience on Scholarly."
		title="Share Feedback"
		@action="feedbackModalOpen = true">
		<button
			class="flex flex-row items-center space-x-2 text-xs font-medium text-purple-500 transition-colors hover:text-purple-700"
			@click="openModal">
			<ChatBubbleLeftIcon class="h-4 w-4" />
			<span class="text-sm font-medium">Share Feedback</span>
		</button>
	</nudge>
</template>
<script lang="ts" setup>
import { ChatBubbleLeftIcon } from "@heroicons/vue/24/outline";

const { feedbackModalOpen } = useFeedback();

const feedbackTxt = ref<"Share Feedback" | "Report a Bug">("Share Feedback");
let timer: NodeJS.Timeout;

onMounted(() => {
	timer = setInterval(() => {
		feedbackTxt.value = feedbackTxt.value === "Share Feedback" ? "Report a Bug" : "Share Feedback";
	}, 5000);
});

onUnmounted(() => {
	clearInterval(timer);
});

function openModal() {
	feedbackModalOpen.value = true;
}
</script>
