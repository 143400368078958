export const useContentSearch = () => {
	type SearchMode = "normal" | "ai" | "message";

	const DEFAULT_SEARCH_MODE: SearchMode = "ai";
	const DEFAULT_SEARCH_SCOPE: "global" | "personal" = "personal";

	const searchMode = useState<SearchMode>("useContentSearch::searchMode", () => DEFAULT_SEARCH_MODE);
	const isSearchOpen = useState("useContentSearch::isSearchOpen", () => false);
	const searchScope = useState<"global" | "personal">("useContentSearch::searchScope", () => DEFAULT_SEARCH_SCOPE);
	const searchInput = useState("useContentSearch::searchInput", () => "");

	return {
		isSearchOpen,
		searchInput,
		searchMode,
		searchScope,
		DEFAULT_SEARCH_MODE,
		DEFAULT_SEARCH_SCOPE,
	};
};
