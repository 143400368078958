import axios from "axios";

export default defineNuxtPlugin(async () => {
	axios.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			const response = error.response
				? error.response
				: {
						status: "Unknown",
						data: {},
					};

			let config = error.response?.config;
			if (!config) {
				return Promise.reject(error);
			}
			const msg = `Network Error occurred. 
									\nCode: ${response.status} 
									\nEndpoint: ${config.url}
									\nResponse Data: ${JSON.stringify(response.data)}`;

			if (error.response && error.response.data["error"] === "Temp token expired") {
				//refresh page
				console.error("User's temp token expired. Refreshing page...");
				window.location.reload();
			}

			console.error(msg);
			return Promise.reject(error);
		},
	);
});
