export function shortenText(txt: string, maxLen: number): string {
	if (txt.length <= maxLen) {
		return txt;
	}
	return txt.substring(0, maxLen) + "...";
}

export function fullyCapitalizeString(txt: string): string {
	return txt
		.split(" ")
		.map((word) => word.charAt(0).toUpperCase() + word.substring(1))
		.join(" ");
}
