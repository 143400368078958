<template>
	<unstyled-modal @close="$emit('close')">
		<div
			:class="{ 'dark:bg-neutral-900': dark, extraClasses: true }"
			:style="extraStyles"
			class="rounded-md bg-white px-12 py-6 shadow-sm"
			role="dialog">
			<div>
				<div class="flex flex-row items-center justify-between">
					<h1
						:class="{ 'dark:text-neutral-400': dark }"
						class="text-2xl font-bold text-black">
						{{ title }}
					</h1>
					<button
						v-if="exit"
						:class="{ 'dark:border-none dark:bg-neutral-800 dark:text-neutral-400 dark:hover:bg-neutral-700': dark }"
						class="rounded-full border border-gray-200 bg-gray-50 p-1 text-gray-700 shadow-sm transition-colors hover:bg-gray-100"
						@click.prevent="$emit('close')">
						<XMarkIcon class="h-6 w-6"></XMarkIcon>
					</button>
				</div>
				<div class="grid h-auto">
					<slot></slot>
				</div>
			</div>
		</div>
	</unstyled-modal>
</template>
<script lang="ts" setup>
import { XMarkIcon } from "@heroicons/vue/24/solid";

const props = defineProps({
	exit: {
		type: Boolean,
		required: false,
		default: false,
	},
	title: {
		type: String,
		required: false,
	},
	extraStyles: {
		type: Object,
		required: false,
	},
	extraClasses: {
		type: String,
		required: false,
	},
	dark: {
		type: Boolean,
		required: false,
		default: false,
	},
});

const shouldClose = ref(false);
</script>
