export type RecentItem = {
	type: RecentFilter;
	model: any;
	title: string;
	createdAt: Date;
	openedAt: Date;
	url: string;

	parentPageId?: string;
};

export const RecentFilters = {
	ALL: "all",
	PAGES: "pages",
	FLASHCARDS: "flashcards",
	PDFS: "PDFs",
	IMAGE: "image",
};
export type RecentFilter = (typeof RecentFilters)[keyof typeof RecentFilters];
