import { FolderItemType, IFolder } from "~/utils/folder";
import axios from "axios";

export const useFolders = () => {
	const folders = useState<IFolder[]>("folders", () => []);

	async function createFolder(name: string | null) {
		const folder = await axios.post("/folder/create", {
			name,
		});
		folders.value.push(folder.data);

		return folder.data as IFolder;
	}

	function getFolder(folderId: string) {
		return folders.value.find((folder) => folder._id === folderId);
	}

	async function fetch() {
		const { data } = await axios.get("/user_folders");

		folders.value = data;
	}

	async function changeFolderName(folderId: string, name: string) {
		const folder = getFolder(folderId);
		if (!folder) return;

		folder.name = name;
		await axios.post(`/folder/${folderId}/change_name`, {
			name,
		});
	}

	async function addItemToFolder(itemId: string, itemType: FolderItemType, folder: IFolder) {
		folder.items.push({
			item_id: itemId,
			item_type: itemType,
			created_at: new Date(),
		});
		await axios.post(`/folder/${folder._id}/add_item`, {
			itemId,
			itemType,
		});
	}

	return {
		folders,
		createFolder,
		addItemToFolder,
		getFolder,
		changeFolderName,
		fetch,
	};
};
