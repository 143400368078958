<template>
	<div
		:class="{ 'opacity-100': closestId === id, 'opacity-0': closestId !== id }"
		:data-page-sort-divider="id"
		:data-page-sort-divider-above="above?._id"
		:data-page-sort-divider-below="below?._id"
		:data-page-sort-divider-parent="parent?._id"
		class="w-full rounded-lg bg-primary bg-opacity-30 py-[1.5px] transition-opacity"
		draggable="true"></div>
</template>
<script lang="ts" setup>
import type { Page } from "~/utils/page";

const props = defineProps<{
	closestId: string | null;
	above: Page | null;
	below: Page | null;
	parent?: Page | null;
}>();

const id = randomUUID();
</script>
