<template>
	<div
		v-if="search"
		class="px-4">
		<h1 class="pt-2 text-base font-semibold text-stone-600">Search Results</h1>
		<div class="mt-1 flex w-full flex-col divide-y divide-gray-100">
			<div
				v-for="item in filteredRecents"
				:class="{ 'bg-gray-100': focusedBtn === item.model._id }"
				:data-content-search-option-id="item.model._id"
				class="flex w-full flex-row items-center gap-3 rounded-md px-4 py-2.5 hover:bg-gray-100"
				role="button"
				@click.prevent="
					navigateTo(item.url);
					$emit('close');
					getPosthog().capture('content_search_ai_mode_used_result_click', { type: item.type });
				">
				<FileIcon
					v-if="item.type === 'pages'"
					class="h-5 w-5 text-gray-400" />
				<FileTextIcon
					v-if="item.type === 'PDFs'"
					class="h-5 w-5 text-red-500" />
				<flashcards-icon
					v-if="item.type === 'flashcards'"
					class="h-5 w-5 text-secondary" />
				<div>
					<h1 class="text-base font-semibold text-stone-600">
						{{ item.title ? item.title : "Untitled" }}
					</h1>
					<div class="flex flex-row items-center gap-2">
						<p class="text-xs text-gray-500">
							{{ item.type === "PDFs" ? "PDF" : item.type === "flashcards" ? "Flashcard" : item.type === "pages" ? "Page" : item.type }}
						</p>
						<div class="h-1 w-1 rounded-full bg-gray-400"></div>
						<p class="text-xs text-gray-500">
							{{ timeAgoPretty(item.model.lastOpened ? item.model.lastOpened : item.model.lastOpenedAt) }}
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { timeAgoPretty } from "~/utils/date_utils";
import { FileIcon, FileTextIcon } from "lucide-vue-next";
import { fastStringSearch } from "~/utils/search_utils";
import { RecentItem } from "~/utils/recents";

const MAX_SEARCH_RESULTS = 10;

const { getRecents } = usePages();

const emits = defineEmits(["switch_mode", "close", "mount"]);

const props = defineProps<{
	search: string;
	focusedBtn: string | null;
}>();

onMounted(() => {
	emits("mount");

	getPosthog().capture("content_search_normal_mode_mounted", {});
});

const filteredRecents = computed(() => {
	const recents = getRecents.value.filter((x) => x.type === "PDFs" || x.type === "flashcards" || x.type === "pages");

	if (!props.search) return recents.slice(0, MAX_SEARCH_RESULTS);

	return fastStringSearch<RecentItem>(props.search, recents, (x) => x.title, {
		limit: MAX_SEARCH_RESULTS,
	});
});
</script>
