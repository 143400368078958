import { Component } from "~//utils/page";
import type { Page } from "~/utils/page";
import axios from "axios";
import { componentUpdatePipeline } from "~/utils/pipeline/model_update_pipeline";
import ObjectID from "bson-objectid";

const PAGE_SYNC_TIME = 0; //0 mils because the serverside sync takes up to 1-2 seconds, so it's quite slow
const PAGE_SYNC_ERROR_ATTEMPT_TIME = 7500; //7.5 seconds

/*
	/v1/pages/:id/toggle_archive_status
	/v1/pages/:id/visibility
	/v1/pages/:id/update_emoji
	/v1/pages/:id/update_title
 */

export namespace PageSyncPipeline {
	enum PageModificationType {
		PAGE_CREATE = "page_create",
		COMPONENT_CREATE = "component_create",
		COMPONENT_DELETE = "component_delete",
		PAGE_COMPONENT_SORT = "page_component_sort",
		PAGE_UPDATE = "page_update",

		//component specific ones
		COMPONENT_CONTENT_UPDATE = "component_content_update",
		COMPONENT_MOVE_PAGE = "component_move_page",
	}

	interface PageComponentSortMetadata {
		componentIds: string[];
	}

	interface ComponentCreateMetadata {
		component: Component;
	}

	interface ComponentDeleteMetadata {
		componentId: string;
	}

	interface ComponentMovePageMetadata {
		componentId: string;
		oldPageId: string;
		targetPageId: string;
	}

	interface PageUpdateMetadata {
		fields: {
			title: string | null;
			emoji: string | null;
			archived: boolean;

			//sharingStatus
			sharingStatus: {
				visibility: "public" | "private";
				publicToSearchEngines: boolean;
				editors: string[]; //emails
				sharedWith: string[]; //emails
			};
		};
	}

	interface ComponentContentUpdate {
		componentId: string;
		newContent: any;
	}

	interface PageCreateMetadata {
		page: Page;
	}

	//GENERIC HELL
	export type PageModification<T extends PageModificationType> = {
		id: string; // ObjectId
		type: T;
		pageId: string; // ObjectId
		createdAt: Date | string; //when parsing from localstorage, it's a string
		metadata: MetadataForType<T>;
	};

	interface MetadataMapping {
		[PageModificationType.COMPONENT_CREATE]: ComponentCreateMetadata;
		[PageModificationType.COMPONENT_DELETE]: ComponentDeleteMetadata;
		[PageModificationType.COMPONENT_CONTENT_UPDATE]: ComponentContentUpdate;
		[PageModificationType.PAGE_COMPONENT_SORT]: PageComponentSortMetadata;
		[PageModificationType.PAGE_CREATE]: PageCreateMetadata;
		[PageModificationType.PAGE_UPDATE]: PageUpdateMetadata;
		[PageModificationType.COMPONENT_MOVE_PAGE]: ComponentMovePageMetadata;
	}

	type MetadataForType<T extends PageModificationType> = T extends keyof MetadataMapping ? MetadataMapping[T] : never;

	function createdAt(request: PageModification<any>) {
		if (typeof request.createdAt === "string") {
			return new Date(request.createdAt);
		}

		return request.createdAt as Date;
	}

	class Pipeline {
		requests = usePageSyncPipeline().storage;

		batch(): Map<string, PageModification<any>[]> {
			const map = new Map<string, PageModification<any>[]>();

			for (const request of this.requests.value) {
				if (!map.has(request.pageId)) {
					map.set(request.pageId, []);
				}

				map.get(request.pageId)!.push(request);
			}

			//sort each page's requests by createdAt
			for (const pageRequests of map.values()) {
				pageRequests.sort((a, b) => createdAt(a).getTime() - createdAt(b).getTime());
			}

			return map;
		}

		pushSpecific<T extends PageModificationType>(request: PageModification<T>) {
			this.requests.value.push(request);
		}

		push<T extends PageModificationType>(type: T, page: Page, metadata: MetadataForType<T>) {
			this.pushSpecific({
				id: ObjectID().toHexString(),
				type,
				pageId: page._id,
				createdAt: new Date(),
				metadata,
			});
		}
	}

	const pipeline = new Pipeline();

	export function syncComponentCreate(page: Page, component: Component) {
		//give it a few seconds to mark as complete (we ensure that it's completed)
		//todo: improve this, just make file uploads & all other component updates based on the sync pipeline rather than the componentUpdatePipeline
		componentUpdatePipeline.markPending(component._id);
		setTimeout(() => {
			componentUpdatePipeline.markComplete(component._id); //since it's always going to be sequential, we can just mark it as complete here
		}, 1500);
		pipeline.push(PageModificationType.COMPONENT_CREATE, page, {
			component,
		});

		page.lastUpdated = new Date();
	}

	export function syncPageCreate(page: Page) {
		pipeline.push(PageModificationType.PAGE_CREATE, page, {
			page,
		});
	}

	export function syncComponentDelete(page: Page, componentId: string) {
		pipeline.push(PageModificationType.COMPONENT_DELETE, page, {
			componentId,
		});

		page.lastUpdated = new Date();
	}

	export function syncComponentMovePage(componentId: string, oldPage: Page, targetPage: Page) {
		pipeline.push(PageModificationType.COMPONENT_MOVE_PAGE, oldPage, {
			componentId,
			oldPageId: oldPage._id,
			targetPageId: targetPage._id,
		});

		targetPage.lastUpdated = new Date();
		oldPage.lastUpdated = new Date();
	}

	export function syncComponentDeleteMultiple(page: Page, componentIds: string[]) {
		for (const componentId of componentIds) {
			syncComponentDelete(page, componentId);
		}

		page.lastUpdated = new Date();
	}

	export function syncPageComponentSort(page: Page, componentIds: string[]) {
		pipeline.push(PageModificationType.PAGE_COMPONENT_SORT, page, {
			componentIds,
		});

		page.lastUpdated = new Date();
	}

	export function syncComponentContentUpdate(page: Page, component: Component) {
		pipeline.push(PageModificationType.COMPONENT_CONTENT_UPDATE, page, {
			componentId: component._id,
			newContent: component.content,
		});

		page.lastUpdated = new Date();
	}

	export function syncPageUpdate(page: Page) {
		//check if there's already a request for this page, if so remove it
		const existingRequests = pipeline.requests.value.filter((request) => request.pageId === page._id);

		for (const request of existingRequests) {
			pipeline.requests.value.splice(pipeline.requests.value.indexOf(request), 1);
		}

		pipeline.push(PageModificationType.PAGE_UPDATE, page, {
			fields: {
				title: page.title,
				emoji: page.emoji,
				archived: page.archived,

				sharingStatus: {
					visibility: page.sharingStatus.visibility,
					publicToSearchEngines: page.sharingStatus.publicToSearchEngines,
					editors: page.sharingStatus.editors,
					sharedWith: page.sharingStatus.sharedWith,
				},
			},
		});

		page.lastUpdated = new Date();
	}

	let isSyncing = false;
	let currentError = 0;

	export function beginUpdateThread() {
		setInterval(async () => {
			if (isSyncing) return;
			if (pipeline.requests.value.length === 0) return;

			preprocessPayloadGraph();

			const batch = pipeline.batch();

			//convert to object
			const obj: any = {};
			const requestIds = new Set<string>();

			for (const [pageId, requests] of batch) {
				obj[pageId] = requests;
				requestIds.add(pageId);
			}

			if (Object.keys(obj).length === 0) return; //no requests to sync (this can happen if all requests are deleted)

			isSyncing = true;

			try {
				const { data } = await axios.post("/page/sync", {
					payload: obj,
				});

				const success = data.success;
				pipeline.requests.value = pipeline.requests.value.filter((c) => !success.includes(c.id));

				currentError = 0;
			} catch (e) {
				console.error(e);
				currentError++;

				if (currentError >= 5) {
					addAlert("We ran into an issue trying to save your data, please refresh the page if this continues to occur. ", "error");
					pipeline.requests.value = pipeline.requests.value.filter((c) => !requestIds.has(c.pageId)); // These are probably invalid, so remove them
				}

				await new Promise((resolve) => setTimeout(resolve, PAGE_SYNC_ERROR_ATTEMPT_TIME)); //wait 5 seconds, then try again (well technically 5.5 seconds)
			} finally {
				isSyncing = false;
			}
		}, PAGE_SYNC_TIME);
	}

	type ComponentId = string;

	function getRequestsByComponent(): Map<ComponentId, PageModification<any>[]> {
		const map = new Map<ComponentId, PageModification<any>[]>(); //componentId -> [requests]

		for (const request of pipeline.requests.value) {
			if (request.type !== PageModificationType.COMPONENT_CONTENT_UPDATE) continue;

			if (!map.has(request.metadata.componentId)) {
				map.set(request.metadata.componentId, []);
			}

			map.get(request.metadata.componentId)!.push(request);
		}

		//sort map
		for (const requests of map.values()) {
			requests.sort((a, b) => createdAt(a).getTime() - createdAt(b).getTime());
		}

		return map;
	}

	function preprocessPayloadGraph() {
		const map = getRequestsByComponent();

		removeDeletedComponentUpdateRequests(map);
		mergeDuplicateComponentUpdates(map);
		insertSortRequests();
	}

	function insertSortRequests() {
		const toAddSort = new Set<string>();

		for (const page of pipeline.requests.value) {
			if (page.type === PageModificationType.COMPONENT_CREATE || page.type === PageModificationType.COMPONENT_DELETE) {
				toAddSort.add(page.pageId);
			}
		}

		const { getPage } = usePages();

		for (const pageId of toAddSort) {
			const page = getPage(pageId);

			if (!page) continue;

			const existingSortRequests = pipeline.requests.value.filter((request) => request.type === PageModificationType.PAGE_COMPONENT_SORT && request.pageId === pageId);

			for (const request of existingSortRequests) {
				pipeline.requests.value.splice(pipeline.requests.value.indexOf(request), 1);
			}

			pipeline.push(PageModificationType.PAGE_COMPONENT_SORT, page, {
				componentIds: page.components,
			});
		}
	}

	function mergeDuplicateComponentUpdates(map: Map<ComponentId, PageModification<any>[]>) {
		for (const entry of map.entries()) {
			const { 0: componentId, 1: requests } = entry;

			let latestRequest: PageModification<any> | undefined = undefined;

			for (const req of requests) {
				if (req.type !== PageModificationType.COMPONENT_CONTENT_UPDATE) continue;

				if (latestRequest === undefined) {
					latestRequest = req;
					continue;
				}

				if (createdAt(latestRequest).getTime() < createdAt(req).getTime()) {
					latestRequest = req;
				}
			}

			if (latestRequest === undefined) continue;

			//remove all other requests
			for (const req of requests) {
				if (req !== latestRequest && req.type === PageModificationType.COMPONENT_CONTENT_UPDATE) {
					if (!pipeline.requests.value.includes(req)) continue;

					pipeline.requests.value.splice(pipeline.requests.value.indexOf(req), 1);
				}
			}
		}
	}

	function removeDeletedComponentUpdateRequests(map: Map<ComponentId, PageModification<any>[]>) {
		//so if a component is deleted, remove all requests for that component
		for (const pageRequests of map.values()) {
			const deleteRequest = pageRequests.find((request) => request.type === PageModificationType.COMPONENT_DELETE);

			if (deleteRequest) {
				//remove all COMPONENT_CONTENT_UPDATE requests for this component
				for (const request of pageRequests) {
					if (request.type === PageModificationType.COMPONENT_CONTENT_UPDATE) {
						const index = pipeline.requests.value.indexOf(request);
						pipeline.requests.value.splice(index, 1);
					}
				}
			}
		}
	}
}
