import posthog from "posthog-js";

export default defineNuxtPlugin((nuxtApp) => {
	if (import.meta.env.MODE === "development") return;

	const client = posthog.init("phc_GN09ONrdaYBqauscqsLwi7o7kbN7Xgt9oUmap1NYAd0", {
		api_host: "https://app.posthog.com",
		loaded: (posthog) => {
			if (import.meta.env.MODE === "development") posthog.debug();
		},
		opt_in_site_apps: true,
	});

	const router = useRouter();
	router.afterEach((to) => {
		posthog.capture("$pageview", {
			current_url: to.fullPath,
		});
	});

	posthog.capture("Website Init", {
		current_url: window.location.href,
	});

	const { user } = useAuth();

	if (user.value) {
		posthog.capture("logged_in_website_init", {
			user_id: user.value?._id,
		});
		posthog.capture("user_retention_capture", {
			user_id: user.value?._id,
			email: user.value?.email,
		});
	}

	return {
		provide: {
			posthog: () => client,
		},
	};
});
