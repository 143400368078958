export default defineNuxtPlugin(async () => {
	const route = useRoute();
	const { getUtmCampaign, getUtmSource } = useAdTracking();

	if (route.query.utm_campaign) {
		getUtmCampaign.value = route.query.utm_campaign.toString();

		getPosthog().capture("scholarly_utm_campaign", {
			utm_campaign: route.query.utm_campaign.toString(),
		});
	}

	if (route.query.utm_source) {
		getUtmSource.value = route.query.utm_source.toString();

		getPosthog().capture("scholarly_utm_source", {
			utm_source: route.query.utm_source.toString(),
		});
	}
});
