import axios from "axios";
import { Plan } from "~/utils/user";

export type PricingTier = {
	currency: string;
	currency_format: string;
	country_code: string;
	monthly: number;
	yearly: number;
};

const DEFAULT_PLUS_TIER: PricingTier = {
	currency: "USD",
	currency_format: "$<price>",
	country_code: "US",
	monthly: 8,
	yearly: 76,
};

const DEFAULT_ULTIMATE_TIER: PricingTier = {
	currency: "USD",
	currency_format: "$<price>",
	country_code: "US",
	monthly: 12,
	yearly: 114,
};

export const useLocalizedPrices = () => {
	const plus = useState<PricingTier>("plus", () => DEFAULT_PLUS_TIER);
	const ultimate = useState<PricingTier>("ultimate ", () => DEFAULT_ULTIMATE_TIER);

	async function fetchLocalizedPrices() {
		const { data } = await axios.get("/stripe/regional_price");
		plus.value = data.PLUS;
		ultimate.value = data.ULTIMATE;
	}

	function priceStr(plan: Plan, rec: "monthly" | "yearly") {
		const tier = plan === Plan.PLUS ? plus.value : ultimate.value;
		return tier.currency_format.replace("<price>", rec === "monthly" ? tier.monthly.toString() : tier.yearly.toString());
	}

	return {
		plus,
		ultimate,
		fetchLocalizedPrices,
		priceStr,
	};
};
