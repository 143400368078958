<template>
	<div class="h-full max-h-[100vh] overflow-hidden bg-gray-50 p-4 !pr-0">
		<div class="flex h-full flex-col">
			<div>
				<div class="relative flex items-center gap-1 text-gray-600">
					<sidebar-profile :placement="'left'" />
					<tooltip tooltip="Hide the sidebar">
						<button
							class="rounded-md p-2 text-gray-500 hover:bg-gray-100"
							@click="$emit('closeSidebar')">
							<PanelLeftClose class="h-4 w-4" />
						</button>
					</tooltip>
				</div>
				<div class="mt-4 space-y-2">
					<nuxt-link
						class="flex flex-row items-center space-x-2 text-xs font-medium text-gray-600 transition-colors hover:text-gray-900"
						to="/home">
						<HomeIcon class="h-3.5 w-3.5" />
						<span class="text-gray-600">Home</span>
					</nuxt-link>
					<nuxt-link
						class="flex flex-row items-center space-x-2 text-xs font-medium text-secondary transition-colors hover:text-primary"
						to="/assistant">
						<BotIcon class="h-3.5 w-3.5" />
						<span>Assistant</span>
					</nuxt-link>
					<invite-friends-button />
					<button
						class="flex flex-row items-center space-x-2 text-xs font-medium text-gray-600 transition-colors hover:text-gray-900"
						@click="toggleSettingsModal('import')">
						<ArrowDownTrayIcon class="h-3.5 w-3.5" />
						<span>Import</span>
					</button>
					<archived-pages-button />
				</div>
			</div>
			<div class="mt-4">
				<div
					aria-label="Create Page"
					class="flex w-full items-center justify-between rounded-md border border-gray-150 bg-white px-2 py-1 text-gray-500"
					role="button"
					@click="isSearchOpen = true">
					<div class="flex flex-row items-center space-x-2">
						<SearchIcon class="h-3.5 w-3.5" />
						<span class="text-sm font-medium"> Search...</span>
					</div>
					<div class="flex flex-row items-center space-x-2">
						<span class="rounded-md border border-gray-200 bg-gray-100 px-1.5 py-0.5 text-xs font-bold text-gray-500"> {{ getMetaKeyName() }} + K </span>
					</div>
				</div>
			</div>
			<div class="flex flex-grow flex-col justify-between overflow-y-auto overflow-x-hidden">
				<div class="py-8">
					<div>
						<favorites-container v-if="favorites.length > 0" />
					</div>
					<div class="mt-3">
						<recents-container />
					</div>
					<div class="mt-2">
						<pages-container />
					</div>
				</div>
				<div class="space-y-4">
					<nuxt-link
						class="flex flex-row items-center space-x-2 text-xs font-medium text-gray-600 transition-colors hover:text-gray-900"
						target="_blank"
						to="/changelog">
						<CubeIcon class="h-3.5 w-3.5" />
						<span>Changelog</span>
					</nuxt-link>
					<div
						v-if="!updateHidden"
						class="rounded-md border border-purple-200 bg-purple-50/30 px-4 py-3 text-xs text-black">
						<div class="flex flex-row items-center justify-between">
							<h1 class="text-sm font-bold">Updated AI Text Completion</h1>
							<button
								class="rounded-full bg-purple-200 p-1 hover:bg-indigo-300"
								@click="
									updateHidden = 'true';
									getPosthog().capture('update-sidebar-closed', {});
								">
								<XIcon class="h-3 w-3" />
							</button>
						</div>
						<p class="mt-2 font-medium text-black">(New) Major overhauls to the text completion feature. It's now much easier to use.</p>
						<div class="mt-4 w-full">
							<button
								class="w-full rounded-md bg-purple-300 py-1 font-semibold hover:bg-indigo-500"
								@click.prevent="
									navigateTo('/help/ai-text-completion', {
										external: true,
										open: {
											target: '_blank',
										},
									});
									updateHidden = 'true';
									getPosthog().capture('help-click-more-info', {});
								">
								Learn More
							</button>
						</div>
					</div>
					<feedback-button />
					<plan-indicator />
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { ArrowDownTrayIcon, CubeIcon, HomeIcon } from "@heroicons/vue/24/outline";
import { BotIcon, PanelLeftClose, SearchIcon, XIcon } from "lucide-vue-next";

const { favorites } = useFavorites();
const { isSearchOpen } = useContentSearch();
const { toggleSettingsModal } = useUserSettings();

const updateHidden = useCookie("updateHidden-3-ai-text", {
	maxAge: 365 * 24 * 60 * 60,
});

function getMetaKeyName() {
	if (import.meta.client) {
		try {
			return navigator.userAgent.indexOf("Mac") !== -1 ? "CMD" : "CTRL";
		} catch (e) {
			return "CTRL";
		}
	}
}
</script>
<style scoped>
.router-link-exact-active {
	@apply text-black;
}
</style>
