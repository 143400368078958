<template>
	<client-only>
		<div
			v-if="!hasMountedAnimation"
			:class="{ '!opacity-0': hasMounted }"
			class="duration-200 fixed z-[999999999999999999] h-screen w-screen bg-white opacity-100 transition-opacity"></div>
		<alerts-container />
		<user-settings-modal
			v-if="settingsModalOpen"
			@close="settingsModalOpen = false" />
		<feedback-modal />
		<!--	<free-trial-modal />-->
		<free-trial-expire-modal />
		<content-search-modal />
		<stripe-purchase-modal />
		<div class="flex-no-wrap flex h-screen overflow-hidden">
			<sidebar-transition>
				<sidebar-full
					v-if="!preferences.sidebar.minimized"
					class="sidebar-width"
					@close-sidebar="preferences.sidebar.minimized = true"></sidebar-full>
			</sidebar-transition>
			<slot />
		</div>
	</client-only>
</template>
<script lang="ts" setup>
import axios from "axios";

const { preferences } = useUserPreferences();
const { settingsModalOpen } = useUserSettings();

const { user } = useAuth();

const hasMounted = ref(false);
const hasMountedAnimation = ref(true);

onMounted(() => {
	hasMounted.value = true;
	setTimeout(() => {
		hasMountedAnimation.value = true;
	}, 250);

	// if (!user.value?.hasReceivedFreeTrial) {
	// 	giveFreeTrialToUser();
	// }
});

async function giveFreeTrialToUser() {
	getPosthog().capture("free_trial_received", {});

	user.value!.plan = {
		type: Plan.ULTIMATE,
		subscriptionId: "unknown",
		createdAt: new Date(),
		updatedAt: new Date(),
		endAt: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
		isFreeTrial: true,
		cancelled: false,
		cancelledAt: new Date(),
	};

	try {
		await axios.post("/stripe/free_trial");
	} catch (e) {
		console.error(e);
	}
}
</script>
<style scoped>
.shadow-all {
	-webkit-box-shadow: 0px 0px 52px 27px rgba(186, 186, 186, 0.91);
	-moz-box-shadow: 0px 0px 52px 27px rgba(186, 186, 186, 0.91);
	box-shadow: 0px 0px 52px 27px rgba(186, 186, 186, 0.91);
}
</style>
