export default defineNuxtPlugin(async () => {
	document.addEventListener("keydown", handleKeyboard);
	document.addEventListener("click", handleClicks);
});

const CLICK_MS_DELAY = 200; //wait 200ms b4 paying attention to the click

function handleClicks(e: MouseEvent) {
	const { openContent, closeSpecific } = usePopupContent();

	if (openContent.value.length === 0) return;
	const lastContent = openContent.value[openContent.value.length - 1];
	const parentContainer = lastContent.element;

	if (Date.now() - lastContent.registeredMs < CLICK_MS_DELAY) return;

	if ((e.target as HTMLElement).closest("[data-no-close-popup='true']")) {
		return;
	}

	//check bounds of parent container and mouse click
	const parentContainerBounds = parentContainer.getBoundingClientRect();
	const mouseX = e.clientX;
	const mouseY = e.clientY;

	//@ts-ignore
	if (e.pointerId === -1 && mouseX === 0 && mouseY === 0) {
		return; //this is to fix flashcard_image_card_upload_dropdown click w/ div w/ role=button not working correctly
	}

	if (mouseX < parentContainerBounds.left || mouseX > parentContainerBounds.right || mouseY < parentContainerBounds.top || mouseY > parentContainerBounds.bottom) {
		closeSpecific(lastContent);
		e.preventDefault();
	}
}

function handleKeyboard(e: KeyboardEvent) {
	const { closeSpecific, openContent } = usePopupContent();

	if (e.key === "Escape") {
		if (document.activeElement?.tagName == "INPUT" || document.activeElement?.tagName == "TEXTAREA") {
			return;
		}
		if (openContent.value.length === 0) return;

		closeSpecific(openContent.value[openContent.value.length - 1]);
		e.preventDefault();
	}
}
