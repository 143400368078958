export function fastStringSearch<T>(
	input: string,
	array: T[],
	comparisonProvider: (item: T) => string | string[],
	options: {
		limit: number;
		filter?: (item: T) => boolean;
	},
) {
	const matches: T[] = [];

	const inputLower = stripNonLetterAndNumberExceptSpace(input.toLowerCase());
	const inputSplit = inputLower.split(" ");

	for (const item of array) {
		if (!item) continue;
		if (options.filter && !options.filter(item)) continue;

		const titles = comparisonProvider(item);
		const titlesArray = Array.isArray(titles) ? titles : [titles];

		let found = false;

		for (const inputPart of inputSplit) {
			if (titlesArray.some((title) => stripNonLetterAndNumberExceptSpace(title.toLowerCase()).includes(inputPart))) {
				found = true;
			} else {
				found = false;
				break;
			}
		}

		if (found) {
			matches.push(item);
		}

		if (matches.length >= options.limit) {
			break;
		}
	}
	return matches;
}

export function stripNonLetterAndNumberExceptSpace(str: string) {
	return str.replace(/[^a-zA-Z0-9 ]/g, "");
}
