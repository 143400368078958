export default defineNuxtPlugin(async () => {
	const nuxt = useNuxtApp();

	if (import.meta.server) {
		const route = useRoute();
		const host = useNuxtApp().ssrContext!!.event.node.req.headers.host;

		if (host === "usescholarly.us" || host === "www.usescholarly.us") {
			navigateTo("https://scholarly.so/" + (route.path === "/" ? "" : route.path), { external: true });
		}

		//if host has www.scholarly.so then redirect to scholarly.so
		if (host === "www.scholarly.so") {
			navigateTo("https://scholarly.so/" + (route.path === "/" ? "" : route.path), { external: true });
		}
	}
});
