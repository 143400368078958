import { useStorage } from "@vueuse/core";

export function useBrowserState<T>(key: string, defaultValue: T) {
	return useStorage<T>(key, defaultValue);
}

export function useStateWithFetch<T>(key: string, makeRequest: () => Promise<T>) {
	const storage = useState<T | undefined>(key, () => undefined);
	const isLoading = useState<boolean>(key + "_loading", () => true);
	const hasLoaded = useState<boolean>(key + "_loaded", () => false);

	if (!hasLoaded.value) {
		makeRequest().then((data) => {
			storage.value = data;
			isLoading.value = false;
			hasLoaded.value = true;
		});
	}

	return [storage, isLoading];
}
