export interface IAlert {
	message: string;
	type: "success" | "error" | "warning" | "info";
}

export const alerts = ref<IAlert[]>([]);

export function addAlert(message: string, type: "success" | "error" | "warning" | "info") {
	const a = { message, type };
	alerts.value.push(a);

	setTimeout(() => {
		alerts.value.splice(alerts.value.indexOf(a), 1);
	}, 2500);
}
