<template>
	<modal
		v-if="shouldOpen"
		@close="close()">
		<div class="flex flex-col items-center justify-center text-center">
			<h1 class="text-lg font-semibold">Your Free Trial Has Expired</h1>
			<p class="mt-4 max-w-[400px] text-sm text-gray-500">
				Thank you for trying out Ultimate! You can continue using Ultimate by subscribing to a plan or you can continue using Scholarly for free.
			</p>
			<p class="mt-1 max-w-[400px] text-sm font-semibold text-gray-500">All your favorite features can be used on the free plan, with just a few limitations.</p>
		</div>
		<div class="mt-6 grid grid-cols-2 gap-2">
			<button
				class="w-full rounded-md bg-primary py-2 text-sm font-semibold text-white transition-all hover:bg-primary/90"
				@click="openPlanModal">
				Subscribe
			</button>
			<button
				class="w-full rounded-md bg-gray-200 py-2 text-sm font-semibold text-gray-800 transition-all hover:bg-gray-300"
				@click="close()">
				Close
			</button>
		</div>
	</modal>
</template>
<script lang="ts" setup>
const { user } = useAuth();

const { togglePlanModal } = usePlanModal();
const cookie = useCookie("has_ignored_free_trial_expire", {
	maxAge: 60 * 60 * 24 * 365 * 10, // 10 years
});

const shouldOpen = computed(() => {
	return user.value?.plan?.isFreeTrial && !isPlanActive() && !cookie.value;
});

function openPlanModal() {
	cookie.value = "true";
	togglePlanModal();
}

function close() {
	cookie.value = "true";
}
</script>
