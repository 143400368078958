import { User } from "~/utils/user";
import axios from "axios";
import { IOrganization } from "~/utils/organization";

export const useAuth = () => {
	const user = useState<User | null>("user", () => null);
	const tempToken = useState<string | null>("temp_token", () => null);
	const organization = useState<IOrganization | null>("user_organization", () => null);

	const serverPagesData = useState<any>("server-pages-data", () => null);
	const serverFavoritesData = useState<any>("server-favorites-data", () => null);

	const setUser = (u: any) => {
		user.value = u;
	};

	const fetchUser = async () => {
		const authToken = getAuthToken();
		if (!authToken) return;

		axios.defaults.headers.common["Authorization"] = `Bearer ${tempToken.value !== null ? tempToken.value : authToken.value}`;

		if (user.value !== null) return;
		try {
			const { data } = await axios.get("/auth/get_user");

			setUser(data.user);
			organization.value = data.organization;
			serverPagesData.value = data.pageData;
			serverFavoritesData.value = data.favorites;
			tempToken.value = data.tempToken;
		} catch (e: any) {
			axios.defaults.headers.common["Authorization"] = null;
		}
	};

	const getAuthToken = () => {
		const twoYears = 1000 * 60 * 60 * 24 * 365 * 2;
		const expires = new Date(Date.now() + twoYears);

		return useCookie("auth_token", {
			expires: expires,
			maxAge: twoYears,
		});
	};

	const processLogin = async (token: string, user: User, tempToken: string) => {
		const cookie = getAuthToken();
		const { setUser } = useAuth();

		cookie.value = token;
		setUser(user);

		axios.defaults.headers.common["Authorization"] = `Bearer ${tempToken}`;

		const { fetchPages } = usePages();
		const { fetchFavorites } = useFavorites();

		await Promise.all([fetchPages(), fetchFavorites()]);
	};

	type PreviousSessionRoute = "home" | "assistant" | string; //string = PageId;
	const getPreviousSessionRoute = () => {
		const twoYears = 1000 * 60 * 60 * 24 * 365 * 2;
		const expires = new Date(Date.now() + twoYears);

		return useCookie<PreviousSessionRoute>("previous_session_route", {
			expires: expires,
			maxAge: twoYears,
		});
	};

	return {
		user,
		setUser,
		fetchUser,
		processLogin,
		getAuthToken,
		tempToken,
		organization,
		serverPagesData,
		serverFavoritesData,
		getPreviousSessionRoute,
	};
};
