<template>
	<div
		:class="{ 'router-link-active': $route.fullPath.includes(page._id) }"
		class="group relative rounded-md text-neutral-800 hover:text-black active:cursor-move"
		draggable="true"
		@contextmenu="handleRightClick"
		@dragstart="$emit('set-drag-target', page, $event)"
		@mouseenter="focused = true"
		@mouseleave="focused = false">
		<div class="flex flex-grow flex-row items-center">
			<button
				class="mr-0.5 rounded-sm text-gray-600 opacity-0 transition-colors group-hover:opacity-100 hover:text-neutral-800"
				@click="childDropdownOpen = !childDropdownOpen">
				<client-only>
					<AnimatedVerticalChevronIcon
						:mode="childDropdownOpen ? 'down' : 'right'"
						class="h-3 w-3" />
				</client-only>
			</button>
			<nuxt-link
				:to="'/page/' + page._id"
				class="flex h-full w-full flex-grow flex-row items-center space-x-1 py-1"
				draggable="false">
				<span
					v-if="page.emoji"
					class="text-xs"
					>{{ page.emoji }}</span
				>
				<p class="page-title text-xs text-inherit">
					{{ shortenText(!page.title ? "Untitled" : page.title, page.emoji ? 25 : 27) }}
				</p>
			</nuxt-link>
		</div>
		<div class="inset-center-y absolute -right-2 overflow-hidden">
			<div
				:class="{ 'bg-gray-50 opacity-100': focused, 'opacity-0': !focused && !dropdownOpen }"
				class="tools flex flex-row items-center gap-2 pl-2 transition-opacity">
				<tooltip tooltip="Show page actions">
					<button @click="dropdownOpen = !dropdownOpen">
						<EllipsisHorizontalIcon class="h-4 w-4 text-gray-700" />
					</button>
				</tooltip>
				<tooltip tooltip="Create a sub page">
					<button @click="createChildPage()">
						<PlusIcon class="h-4 w-4 text-gray-700" />
					</button>
				</tooltip>
			</div>
		</div>
		<menu-transition>
			<dropdown
				v-if="dropdownOpen"
				class="inset-center-x mt-[6rem]"
				@close="dropdownOpen = false">
				<dropdown-item
					:icon="TrashIcon"
					@click="
						archiveRoom();
						dropdownOpen = false;
					">
					Archive
				</dropdown-item>
				<dropdown-item
					:icon="StarIcon"
					@click="
						toggleFavorite();
						dropdownOpen = false;
					">
					{{ isFavorite(page._id) ? "Remove from favorites" : "Add to favorites" }}
				</dropdown-item>
				<dropdown-item
					:icon="DocumentDuplicateIcon"
					@click="
						duplicatePage(page._id, true);
						dropdownOpen = false;
					">
					Duplicate
				</dropdown-item>
				<dropdown-item
					:icon="LinkIcon"
					@click="
						shareRoom();
						dropdownOpen = false;
					">
					Share
				</dropdown-item>
				<hr class="border-gray-200" />
				<div class="px-1.5 pb-0.5 pt-1.5 text-xs text-gray-500">
					<h1 class="font-semibold">
						{{ shortenText(page.title ? page.title : "Untitled", 22) }}
					</h1>
					<p>Created {{ mdy(page.createdAt.toString()) }}</p>
				</div>
			</dropdown>
		</menu-transition>
	</div>
	<right-click-dropdown
		:mouse-position="mousePosition"
		:show="showRightClickDropdown"
		class="w-[200px]"
		@close="showRightClickDropdown = false">
		<dropdown-item
			:icon="TrashIcon"
			@click="
				archiveRoom();
				showRightClickDropdown = false;
			">
			Archive
		</dropdown-item>
		<dropdown-item
			:icon="StarIcon"
			@click="toggleFavorite">
			{{ isFavorite(page._id) ? "Remove from favorites" : "Add to favorites" }}
		</dropdown-item>
		<dropdown-item
			:icon="DocumentDuplicateIcon"
			@click="
				duplicatePage(page._id, true);
				showRightClickDropdown = false;
			">
			Duplicate
		</dropdown-item>
		<dropdown-item
			:icon="LinkIcon"
			@click="
				shareRoom();
				showRightClickDropdown = false;
			">
			Share
		</dropdown-item>
		<hr class="border-gray-200" />
		<div class="px-1.5 pb-0.5 pt-1.5 text-xs text-gray-500">
			<h1 class="font-semibold">
				{{ page.title }}
			</h1>
			<p>Created {{ mdy(page.createdAt.toString()) }}</p>
		</div>
	</right-click-dropdown>
	<div
		v-if="childDropdownOpen"
		class="ml-5 border-l border-gray-200 pl-2">
		<page-sort-divider
			v-if="closestDragId"
			:above="null"
			:below="children[children.length - 1]"
			:closest-id="closestDragId"
			:parent="page"
			class="ml-0" />
		<h1
			v-if="children.length === 0 && flashcardChilds.length === 0 && pdfChilds.length === 0"
			class="py-2 text-xs font-medium text-gray-500">
			Sub Pages, flashcards & PDFs will appear here
		</h1>
		<div v-if="flashcardChilds.length != 0">
			<div
				v-for="child in flashcardChilds"
				:key="child._id">
				<nuxt-link
					:to="`/page/${page._id}/study/${child._id}`"
					class="parent my-[1px] flex items-center space-x-2 py-0.5 text-neutral-800 hover:text-black">
					<span class="rounded-sm border border-gray-200 bg-gray-50 p-0.5 shadow-sm">
						<FlashcardsIcon class="h-3 w-3 text-gray-700" />
					</span>
					<p class="text-xs">{{ shortenText(child.content.title || "Untitled Flashcards", 20) }}</p>
				</nuxt-link>
			</div>
		</div>
		<div
			v-for="child in pdfChilds"
			:key="child._id">
			<nuxt-link
				:to="`/pdf/${child._id}`"
				class="parent my-[1px] flex items-center space-x-2 py-0.5 text-neutral-800 hover:text-black">
				<span class="rounded-sm border border-gray-200 bg-gray-50 p-0.5 shadow-sm">
					<FileIcon class="h-3 w-3 text-gray-700" />
				</span>
				<p class="text-xs">{{ shortenText(child.content.file.name, 20) }}</p>
			</nuxt-link>
		</div>
		<div
			v-for="(child, index) in children"
			:key="child._id">
			<sidebar-page-editor
				:closestDragId="closestDragId"
				:page="child"
				@set-drag-target="(x, y) => $emit('set-drag-target', x, y)" />
			<page-sort-divider
				v-if="closestDragId"
				:above="child"
				:below="children[index + 1]"
				:closest-id="closestDragId"
				:parent="page"
				class="ml-4" />
		</div>
	</div>
</template>
<script lang="ts" setup>
import type { Page } from "~/utils/page";
import { EllipsisHorizontalIcon, PlusIcon } from "@heroicons/vue/24/solid";
import { DocumentDuplicateIcon, LinkIcon, StarIcon, TrashIcon } from "@heroicons/vue/24/outline";
import AnimatedVerticalChevronIcon from "~/components/AnimatedChevronIcon.vue";
import { FileIcon } from "lucide-vue-next";
import { PageSyncPipeline } from "~/utils/sync/page_sync_pipeline";
import syncPageUpdate = PageSyncPipeline.syncPageUpdate;

const props = defineProps<{
	page: Page;
	closestDragId?: string | null;
}>();
const { pages, createPage, duplicatePage, getFullComponents } = usePages();
const { addFavorite, isFavorite, removeFavorite } = useFavorites();
const childDropdownOpen = useBrowserState(`${props.page._id}-sidebar-dropdown-open`, false);
const dropdownOpen = ref(false);
const focused = ref(false);

const mousePosition = ref({ x: 0, y: 0 });
const showRightClickDropdown = ref(false);

const components = getFullComponents(props.page._id);

//if the current route is a child of this page, open the dropdown
const { currentRoute } = useRouter();

const children = computed(() => {
	const c = props.page.children ? props.page.children : [];

	return c.filter((child) => !child.archived);
});

const flashcardChilds = computed(() => {
	return components.value.filter((component) => component.type === ComponentType.FLASH_CARDS);
});

const pdfChilds = computed(() => {
	return components.value.filter(
		(component) => component.type === ComponentType.IMAGE && component.content.file != null && component.content.file.id && component.content.file.id.endsWith(".pdf"),
	);
});

watch(
	() => currentRoute.value,
	() => {
		children.value.forEach((child) => {
			if (currentRoute.value.fullPath.includes(child._id) && !childDropdownOpen.value) {
				childDropdownOpen.value = true;
			}
		});
	},
);

onMounted(() => {
	children.value.forEach((child) => {
		if (currentRoute.value.fullPath.includes(child._id)) {
			childDropdownOpen.value = true;
		}
	});
});

function handleRightClick(e: MouseEvent) {
	showRightClickDropdown.value = true;
	mousePosition.value = { x: e.clientX, y: e.clientY };

	e.preventDefault();
}

function toggleFavorite() {
	if (isFavorite(props.page._id)) {
		removeFavorite(props.page._id);
	} else {
		addFavorite(props.page._id, "page", props.page);
	}

	showRightClickDropdown.value = false;
}

async function createChildPage() {
	await createPage(props.page);
}

async function archiveRoom() {
	props.page.archived = true;
	props.page.archivedAt = new Date();
	dropdownOpen.value = false;

	addAlert("Archived Page!", "success");

	syncPageUpdate(props.page);
}

async function shareRoom() {
	const url = `https://scholarly.so/s/${props.page._id}?utm_source=share&utm_medium=web&utm_campaign=share`;

	navigator.clipboard.writeText(url).then(() => {
		addAlert("Copied to clipboard!", "success");
	});

	dropdownOpen.value = false;

	try {
		if (props.page.sharingStatus.visibility === "public") return;

		syncPageUpdate(props.page);
	} catch (e) {
		addAlert("Failed to update visibility", "error");

		console.log(e);
	}
}
</script>
<style scoped>
.router-link-active .page-title {
	@apply font-semibold text-black;
}
</style>
