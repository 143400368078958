<template>
	<div>
		<h1 class="w-full border-b border-gray-200 pb-4 font-bold">School</h1>
		<div
			v-if="organization"
			class="mt-4 flex flex-row items-center justify-between">
			<div>
				<h1 class="text-sm font-semibold">
					{{ organization!!.primary_name }}
				</h1>
				<p class="mt-1 max-w-[30vw] text-xs text-gray-500">You're part of this school.</p>
			</div>
			<button
				class="rounded-lg border border-red-800 px-4 py-2 text-sm font-semibold text-red-800 transition-colors hover:bg-red-100"
				@click="leaveSchool">
				Leave school
			</button>
		</div>
		<div v-else>
			<h1 class="mt-4 text-lg font-bold">You're not part of any school.</h1>
			<p class="mt-1 text-sm text-gray-500">Join a school to get started.</p>
			<div class="mt-4">
				<div class="styled-input-with-icon">
					<input
						v-model="schoolSearch"
						placeholder="Search for a school"
						type="text"
						@input="searchOpen = true" />
					<SearchIcon class="text-gray-400" />
				</div>
				<dropdown
					v-if="searchOpen"
					class="mt-2"
					width="400px"
					@close="searchOpen = false">
					<div class="px-2">
						<h1 class="text-lg font-bold">Search Results</h1>
					</div>
					<p
						v-if="schools.length === 0"
						class="px-2 text-gray-600">
						No results found. Did we miss your school?
						<a
							class="text-primary underline"
							href="mailto:hello@scholarly.so">
							Contact us
						</a>
						with just the name of your school, and we'll add it.
					</p>
					<div v-else>
						<div class="mt-1 flex flex-col gap-1 px-2">
							<button
								v-for="school in filteredSchools"
								class="rounded-lg px-4 py-2 text-left hover:bg-gray-100"
								@click.prevent="
									selectedSchool = school;
									schoolSearch = school.primary_name;
									searchOpen = false;
								">
								<h1 class="text-base font-bold">
									{{ school.primary_name }}
								</h1>
								<p class="text-base text-gray-600">
									{{ school.information.website }}
								</p>
							</button>
						</div>
					</div>
				</dropdown>
				<div
					v-if="selectedSchool"
					class="mt-4 inline-block">
					<spinning-button @click="joinSchool"> Join school</spinning-button>
				</div>
			</div>
			<!--			<div v-if="schools.length === 0" class="absolute inset-center-xy">-->
			<!--				<spin/>-->
			<!--			</div>-->
			<!--			<div v-else>-->

			<!--			</div>-->
		</div>
	</div>
</template>
<script lang="ts" setup>
import axios from "axios";
import { IOrganization } from "~/utils/organization";
import { SearchIcon } from "lucide-vue-next";
import { fastStringSearch } from "~/utils/search_utils";

const { user, organization } = useAuth();

const props = defineProps<{
	schools: IOrganization[];
}>();

const schoolSearch = ref("");
const searchOpen = ref(false);
const selectedSchool = ref<IOrganization | null>(null);

async function leaveSchool() {
	organization.value = null;
	addAlert("Left school", "success");
	try {
		await axios.post("/orgs/leave");
	} catch (e: any) {
		console.log(e);
		addAlert("Failed to leave school", "error");
	}
}

const filteredSchools = computed(() => {
	return fastStringSearch(
		schoolSearch.value,
		props.schools,
		(org) => {
			return [org.primary_name, ...org.secondary_names, ...org.email_domains, org.information.website];
		},
		{
			limit: 5,
		},
	);
});

let joiningSchool = false;

async function joinSchool() {
	if (joiningSchool) {
		return;
	}
	if (!selectedSchool.value) {
		return;
	}
	joiningSchool = true;
	organization.value = selectedSchool.value;
	addAlert("Joined school", "success");
	try {
		await axios.post("/orgs/join", {
			org_id: selectedSchool.value._id,
		});
		getPosthog().capture("joined_school_through_settings", {
			school_id: selectedSchool.value.primary_name,
		});
	} catch (e: any) {
		console.log(e);
		addAlert("Failed to join school", "error");
	} finally {
		joiningSchool = false;
	}
}
</script>
