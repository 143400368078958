<template>
	<button
		v-if="false"
		class="flex flex-row items-center space-x-2 text-sm font-medium text-gray-600"
		@click="modalOpen = !modalOpen">
		<UserGroupIcon class="h-4 w-4" />
		<span class="text-gray-600">Invite Friends</span>
	</button>
	<opacity-transition>
		<modal
			v-if="modalOpen"
			:exit="true"
			title="Invite Friends"
			@close="modalOpen = false">
			<p class="mt-2 text-gray-500">Invite your friends to join Scholarly and get <br />a free week of Scholarly Plus for each friend that joins.</p>
			<div class="mt-6 grid w-full grid-cols-2 gap-4">
				<button
					class="flex flex-row items-center space-x-2 rounded-md border border-gray-300 px-8 py-2 text-sm font-bold text-gray-600 shadow-sm"
					@click.prevent="emailState = !emailState">
					<EnvelopeIcon class="h-6 w-6" />
					<span>Invite by Email</span>
				</button>
				<button
					class="flex flex-row items-center space-x-2 rounded-md border border-gray-300 px-8 py-2 text-sm font-bold text-gray-600 shadow-sm"
					@click="openQrCode()">
					<QrCodeIcon class="h-6 w-6" />
					<span>Scan QR Code</span>
				</button>
			</div>
			<menu-transition>
				<div
					v-if="emailState"
					class="mt-2 rounded-md border border-gray-300 px-4 py-2 shadow-sm">
					<div>
						<h3 class="text-md text-primary-dark font-bold">Type in your friend's email.</h3>
						<small>Your friend will be sent your first and last name when you send a referral.</small>
					</div>
					<form @submit.prevent="submitEmail()">
						<div class="styled-input mt-4">
							<input
								v-model="email"
								class="w-full"
								placeholder="Friend's Email"
								type="email"
								@keydown="checkEmailState()" />
						</div>
						<spinning-button
							:disabled="!isEmailValid"
							:icon="PaperAirplaneIcon"
							:spinning="sendingEmail"
							class="mt-4"
							type="submit"
							@click="submitEmail()"
							>Send Referral
						</spinning-button>
					</form>
				</div>
			</menu-transition>
			<menu-transition>
				<div
					v-if="qrCodeOpen"
					class="mt-2 rounded-md border border-gray-300 px-4 py-2 shadow-sm">
					<div>
						<h3 class="text-md text-primary-dark font-bold">QR Code.</h3>
						<small>Have a friend scan this QR Code to begin the referral process!</small>
					</div>
					<div class="mt-4 flex justify-center">
						<div v-if="qrCode == null">
							<spin />
						</div>
						<img
							v-if="qrCode != null"
							:src="`data:image/png;base64, ${qrCode}`"
							alt="Qr Code"
							class="w-48" />
					</div>
				</div>
			</menu-transition>
		</modal>
	</opacity-transition>
</template>
<script lang="ts" setup>
import { EnvelopeIcon, PaperAirplaneIcon, QrCodeIcon, UserGroupIcon } from "@heroicons/vue/24/outline";
import axios from "axios";
import type { Ref } from "vue";

const modalOpen = ref(false);

//EMAIL

const isEmailValid = ref(true);
const emailState = ref(false);
const email: Ref<string | null> = ref(null);
const sendingEmail = ref(false);

//QR CODE

const qrCodeOpen = ref(false);
const qrCode: Ref<string | null> = ref(null);

function checkEmailState() {
	const emailMatcher =
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	if (email.value == null) return;

	isEmailValid.value = emailMatcher.test(email.value);
}

async function fetchQrCode() {
	const response = await axios.get(`/referrals/gen_qr_code?format=base64`);

	qrCode.value = response.data;
}

function openQrCode() {
	if (qrCode.value == null) {
		fetchQrCode();
	}

	qrCodeOpen.value = !qrCodeOpen.value;
}

function submitEmail() {
	if (!isEmailValid || email.value == null) return;
	if (sendingEmail.value) return;

	sendingEmail.value = true;

	axios
		.post("/referrals/send_email", {
			email: email.value,
		})
		.then(() => {
			sendingEmail.value = false;
			emailState.value = false;
			email.value = null;
			addAlert("Email sent!", "success");
		})
		.catch((e) => {
			sendingEmail.value = false;
			console.log(e);
			addAlert("Something went wrong!", "error");
		});
}
</script>
