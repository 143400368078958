import axios from "axios";

export interface IFavoriteModel {
	_id: string;
	userId: string;

	cardId: string;
	componentId: string;
	createdAt: Date;
}

export const useFlashcardFavorites = () => {
	const favorites = useBrowserState<IFavoriteModel[]>("flashcard_favorites", []);

	async function loadFavorites() {
		try {
			const { data } = await axios.get("/favorite/flashcard");

			favorites.value = data;
		} catch (e) {
			console.log(e);
		}
	}

	function isFavorite(cardId: string) {
		return favorites.value.some((f) => f.cardId === cardId);
	}

	async function addFavorite(pageId: string, cardId: string, componentId: string) {
		favorites.value.push({
			_id: "",
			userId: "",
			cardId,
			componentId,
			createdAt: new Date(),
		});
		await axios.post("/favorite/flashcard/" + pageId + "/" + componentId + "/batch", {
			requests: [
				{
					cardId,
					action: "add",
				},
			],
		});
	}

	async function removeFavorite(pageId: string, cardId: string, componentId: string) {
		const index = favorites.value.findIndex((f) => f.cardId === cardId);
		if (index !== -1) {
			favorites.value.splice(index, 1);
		}

		await axios.post("/favorite/flashcard/" + pageId + "/" + componentId + "/batch", {
			requests: [
				{
					cardId,
					action: "remove",
				},
			],
		});
	}

	return {
		favorites,
		loadFavorites,
		addFavorite,
		removeFavorite,
		isFavorite,
	};
};
