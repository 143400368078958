<template>
	<unstyled-modal
		v-if="isSearchOpen"
		backdropColor="bg-black/20"
		@close="isSearchOpen = false">
		<div
			ref="container"
			class="w-[750px] rounded-lg border border-gray-150">
			<div class="absolute -top-9 left-0">
				<div class="flex w-full flex-row items-center gap-1 rounded-lg bg-white px-2 py-1">
					<div>
						<div
							v-if="searchScope === 'personal'"
							:data-no-close-popup="true"
							class="flex flex-row items-center gap-2 rounded-md bg-gray-100 px-2 py-1 text-sm font-semibold text-gray-500 shadow-sm transition-colors">
							<LibraryIcon class="h-4 w-4" />
							<span> Personal Library </span>
						</div>
						<div
							v-if="searchScope === 'global'"
							:data-no-close-popup="true"
							class="flex flex-row items-center gap-2 rounded-md bg-gray-100 px-2 py-1 text-sm font-semibold text-gray-500 shadow-sm transition-colors">
							<GlobeIcon class="h-4 w-4" />
							<span> Global Library </span>
						</div>
					</div>
					<div>
						<div
							v-if="searchMode === 'normal'"
							:data-no-close-popup="true"
							class="flex flex-row items-center gap-2 rounded-md bg-gray-100 px-2 py-1 text-sm font-semibold text-gray-500 shadow-sm transition-colors">
							<SearchIcon class="h-4 w-4" />
							<span> Normal Search </span>
						</div>
						<div
							v-if="searchMode === 'ai'"
							:data-no-close-popup="true"
							class="flex flex-row items-center gap-2 rounded-md bg-gray-100 px-2 py-1 text-sm font-semibold text-gray-500 shadow-sm transition-colors">
							<SparklesIcon class="ai-tool-color h-4 w-4" />
							<span> AI Search </span>
						</div>
						<div
							v-if="searchMode === 'message'"
							:data-no-close-popup="true"
							class="flex flex-row items-center gap-2 rounded-md bg-gray-100 px-2 py-1 text-sm font-semibold text-gray-500 shadow-sm transition-colors">
							<BotIcon class="h-4 w-4 text-secondary" />
							<span> Scholarly Assistant </span>
						</div>
					</div>
				</div>
			</div>
			<div class="rounded-t-lg bg-white">
				<div
					:class="{ 'border-b border-gray-100': search }"
					class="flex w-full flex-row items-center px-4 py-2">
					<input
						ref="searchRef"
						v-model="search"
						:placeholder="inputPlaceholderOptions[Math.floor(Math.random() * inputPlaceholderOptions.length)]"
						class="w-full bg-transparent py-2 text-lg outline-none focus:outline-none"
						type="text" />
				</div>
				<div class="max-h-[430px] min-h-[430px] overflow-y-auto">
					<div class="mx-4 border-b border-gray-100">
						<h1 class="pt-2 text-base font-semibold text-stone-600">Actions</h1>
						<content-search-actions :focused-btn="focusedBtn" />
					</div>
					<content-search-normal-options
						v-if="searchMode === 'normal'"
						:focusedBtn="focusedBtn"
						:search="search"
						@close="isSearchOpen = false"
						@mount="focusOnFirst" />
					<content-search-ai-mode
						v-if="searchMode === 'ai'"
						:focusedBtn="focusedBtn"
						:search="search"
						@close="isSearchOpen = false"
						@mount="focusOnFirst" />
					<content-search-mode-message
						v-if="searchMode === 'message'"
						:focusedBtn="focusedBtn"
						:search="search"
						@close="isSearchOpen = false"
						@mount="focusOnFirst" />
				</div>
			</div>
			<div class="rounded-b-lg border-t border-gray-200 bg-gray-100 px-4 py-2">
				<div class="flex flex-row items-center justify-between">
					<div class="flex flex-row items-center gap-1">
						<ArrowUpDown class="h-3.5 w-3.5 text-gray-600" />
						<span class="text-xs font-medium text-stone-600"> Select </span>
					</div>
					<p class="text-xs font-medium text-stone-600">Press <span class="font-bold">Enter</span> to goto content</p>
				</div>
			</div>
		</div>
	</unstyled-modal>
</template>
<script lang="ts" setup>
import { ArrowUpDown, BotIcon, GlobeIcon, LibraryIcon, SearchIcon, SparklesIcon } from "lucide-vue-next";

const { isSearchOpen, searchInput, searchMode, searchScope, DEFAULT_SEARCH_MODE, DEFAULT_SEARCH_SCOPE } = useContentSearch();
const search = searchInput;
const searchRef = ref<HTMLInputElement | null>(null);

const container = ref<HTMLElement | null>(null);
const focusedBtn = ref<"action_ai" | "action_back_from_ai" | string | null>(null);

const inputPlaceholderOptions = [
	"Ask 'Flashcards about France'..",
	"Ask 'PDF which talked about Human Biology'..",
	"Ask 'Pages about the French Revolution'..",
	"Ask 'Flashcards about the Solar System'..",
	"Ask 'PDFs about the Industrial Revolution'..",
];

watch(searchRef, (value) => {
	if (value) {
		value.focus();
	}
});

watchEffect(() => {
	if (!isSearchOpen.value) {
		focusedBtn.value = null;
		searchMode.value = DEFAULT_SEARCH_MODE;
		searchScope.value = DEFAULT_SEARCH_SCOPE;
		search.value = "";
	}
});

watchEffect(() => {
	if (isSearchOpen.value) {
		getPosthog().capture("opened_content_search_modal", {});
	}
});

watchEffect(() => {
	if (searchMode.value !== "ai" && searchScope.value !== "personal") {
		searchScope.value = "personal";
		addAlert("You can only search global content in AI mode.", "error");
	}
});

onMounted(() => {
	window.addEventListener("keydown", onKeyboard);
});

onUnmounted(() => {
	window.removeEventListener("keydown", onKeyboard);
});

function focusOnFirst() {
	const ids = getPossibleOptions();

	if (ids.length > 0) {
		focusedBtn.value = ids[0];
	}
}

function getPossibleOptions() {
	const ids: string[] = [];

	if (!container.value) {
		return ids;
	}

	for (const elm of container.value!!.querySelectorAll("[data-content-search-option-id]")) {
		ids.push(elm.getAttribute("data-content-search-option-id")!!);
	}

	return ids;
}

function onKeyboard(e: KeyboardEvent) {
	if (e.key === "k" && (e.ctrlKey || e.metaKey)) {
		isSearchOpen.value = !isSearchOpen.value;
		e.preventDefault();
		if (isSearchOpen.value) {
			getPosthog().capture("toggled_content_search_modal_cmd_k", {});
		}
		return;
	}

	if (!isSearchOpen.value) {
		return;
	}

	const ids = getPossibleOptions();

	if (e.key === "ArrowUp") {
		let index = ids.indexOf(focusedBtn.value!);

		if (index === 0) {
			focusedBtn.value = ids[ids.length - 1];
		} else {
			focusedBtn.value = ids[index - 1];
		}

		e.preventDefault();
	} else if (e.key === "ArrowDown") {
		let index = ids.indexOf(focusedBtn.value!);

		if (index === ids.length - 1) {
			focusedBtn.value = ids[0];
		} else {
			focusedBtn.value = ids[index + 1];
		}
		e.preventDefault();
	} else if (e.key === "Enter") {
		(container.value?.querySelector(`[data-content-search-option-id="${focusedBtn.value}"]`)!! as HTMLElement).click();
		e.preventDefault();
	}

	const focusedDiv = container.value?.querySelector(`[data-content-search-is-focused="true"]`) as HTMLElement;

	if (focusedDiv) {
		container.value?.scrollTo({
			top: focusedDiv.offsetTop - 100,
			behavior: "smooth",
		});
	}
}
</script>
