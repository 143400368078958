<template>
	<modal
		v-if="isOpen"
		@close="close()">
		<div class="flex flex-col items-center justify-center text-center">
			<div>
				<NuxtImg
					alt="Parting Face"
					class="h-full rounded-xl"
					width="100px"
					height="100px"
					type="webp"
					src="/microsoft_emojis/partying_face_3d.png" />
			</div>
			<h1 class="mt-4 text-base font-semibold">Thank you for your purchase!</h1>
			<p class="mt-1 max-w-[250px] text-sm text-gray-500">
				Your purchase was successful. It may take up to a minute for your account to be updated, depending on your internet connection.
			</p>
		</div>
		<div class="mt-6">
			<button
				class="w-full rounded-md bg-black py-2 text-sm font-semibold text-white transition-all hover:bg-black/90"
				@click="close()">
				Continue
			</button>
		</div>
	</modal>
</template>
<script lang="ts" setup>
const isOpen = useRoute().query.stripe_purchase_hit === "true";

function close() {
	window.location.href = "/home";
}
</script>
