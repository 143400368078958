import axios from "axios";
import { useLocalStorage } from "@vueuse/core";

const FETCH_CHART_DATA_INTERVAL = 1000 * 60 * 5;

const HOME_DATA_LOCAL_STORAGE_OPTIONS = {
	deep: true,
	serializer: {
		read: (value: string) => {
			if (value == "[object Object]") {
				return null;
			}
			try {
				return JSON.parse(value);
			} catch (error) {
				return null;
			}
		},
		write: (value: any) => {
			return JSON.stringify(value);
		},
	},
};

export const useHomeData = () => {
	type ChartData = {
		labels: string[];
		data: number[];
		growthPercent: number;
	};

	const dataCardsStudied = useLocalStorage<ChartData | null>("home_data::cards_studied", null, HOME_DATA_LOCAL_STORAGE_OPTIONS);
	const dataCardsCorrect = useLocalStorage<ChartData | null>("home_data::cards_correct", null, HOME_DATA_LOCAL_STORAGE_OPTIONS);
	const pageViewsData = useLocalStorage<ChartData | null>("home_data::page_views", null, HOME_DATA_LOCAL_STORAGE_OPTIONS);
	const dataStreaks = useLocalStorage<{
		week: boolean[];
		streak: number;
	} | null>("home_data::streaks", null, HOME_DATA_LOCAL_STORAGE_OPTIONS);
	const mostUsedDecks = useLocalStorage<string[]>("home_data::most_used_decks", null, HOME_DATA_LOCAL_STORAGE_OPTIONS);

	const lastFetchDate = useState<number | null>("home-data-last-fetch-date", () => null);

	async function fetchChartData() {
		try {
			const { data } = await axios.get("/user/metrics/home_charts");

			dataCardsStudied.value = data.studied;
			dataCardsCorrect.value = data.correct;
			dataStreaks.value = data.streak;
			pageViewsData.value = data.pageViews;
			mostUsedDecks.value = data.mostUsedDecks;

			lastFetchDate.value = Date.now();
		} catch (error) {
			addAlert("We had some trouble fetching widget data", "error");
			console.error(error);
		}
	}

	function shouldFetchChartData() {
		if (!lastFetchDate.value) {
			return false;
		}

		const now = Date.now();
		const diff = now - lastFetchDate.value;

		return diff > FETCH_CHART_DATA_INTERVAL;
	}

	return {
		dataCardsStudied,
		dataCardsCorrect,
		pageViewsData,
		mostUsedDecks,
		dataStreaks,
		fetchChartData,
		shouldFetchChartData,
	};
};
