<template>
	<modal
		:exit="true"
		:title="`Upgrade to ${plan === Plan.PLUS ? 'Plus' : 'Ultimate'}`"
		@close="closePlanModal()">
		<h1 class="mt-1 text-base text-gray-700">
			Unlock various AI features with the {{ plan === Plan.PLUS ? "Plus" : "Ultimate" }} plan.
			<br />
			Your support helps us improve Scholarly and build more features.
		</h1>
		<div
			v-if="getCurrentActivePlan() === Plan.FREE"
			class="mt-4">
			<div class="mt-1 inline-block w-full rounded-full bg-gray-100 p-0.5">
				<div class="grid grid-cols-2 gap-1">
					<button
						v-for="f in [Plan.ULTIMATE, Plan.PLUS]"
						:class="{ '!bg-white !font-bold text-gray-800 shadow-sm': plan === f }"
						class="rounded-full bg-gray-100 px-3 py-0.5 font-medium capitalize text-gray-500 transition-colors hover:bg-white hover:text-gray-800 hover:shadow-sm"
						@click="plan = f">
						{{ f.toLocaleLowerCase() }} plan
					</button>
				</div>
			</div>
		</div>
		<div class="mt-6 flex flex-row items-start space-x-8">
			<div
				v-if="plan === Plan.PLUS"
				class="flex-grow space-y-4">
				<div class="flex flex-row items-center space-x-4">
					<div class="rounded-full border border-gray-200 bg-white p-2 text-gray-700 shadow-sm">
						<TypeIcon class="h-5 w-5" />
					</div>
					<div>
						<h1 class="text-base font-semibold">Unlimited AI text completions per day</h1>
						<p class="text-base text-gray-500">Write notes alongside AI.</p>
					</div>
				</div>
				<div class="flex flex-row items-center space-x-4">
					<div class="rounded-full border border-gray-200 bg-white p-2 text-gray-700 shadow-sm">
						<FileText class="h-5 w-5" />
					</div>
					<div>
						<h1 class="text-base font-semibold">Up to {{ PRO_PLAN.pdf_to_flashcard_pages }} PDF pages at a time</h1>
						<p class="text-base text-gray-500">Convert PDFs to flashcards.</p>
					</div>
				</div>
				<div class="flex flex-row items-center space-x-4">
					<div class="rounded-full border border-gray-200 bg-white p-2 text-gray-700 shadow-sm">
						<BotIcon class="h-5 w-5" />
					</div>
					<div>
						<h1 class="text-base font-semibold">{{ PRO_PLAN.assistant_messages }} Daily AI Assistant messages</h1>
						<p class="text-base text-gray-500">Learn alongside an AI tutor.</p>
					</div>
				</div>
				<div class="flex flex-row items-center space-x-4">
					<div class="rounded-full border border-gray-200 bg-white p-2 text-gray-700 shadow-sm">
						<MessageSquareCode class="h-5 w-5" />
					</div>
					<div>
						<h1 class="text-base font-semibold">GPT 4o Access</h1>
						<p class="text-base text-gray-500">The best AI model out there. (ChatGPT Plus)</p>
					</div>
				</div>
			</div>
			<div
				v-else
				class="flex-grow space-y-4">
				<div class="flex flex-row items-center space-x-4">
					<div class="rounded-full border border-gray-200 bg-white p-2 text-gray-700 shadow-sm">
						<TypeIcon class="h-5 w-5" />
					</div>
					<div>
						<h1 class="text-base font-semibold">Unlimited AI text completions</h1>
						<p class="text-base text-gray-500">Write notes alongside AI.</p>
					</div>
				</div>
				<div class="flex flex-row items-center space-x-4">
					<div class="rounded-full border border-gray-200 bg-white p-2 text-gray-700 shadow-sm">
						<FileText class="h-5 w-5" />
					</div>
					<div>
						<h1 class="text-base font-semibold">Up to {{ ULTIMATE_PLAN.pdf_to_flashcard_pages }} PDF pages at a time</h1>
						<p class="text-base text-gray-500">Convert PDFs to flashcards.</p>
					</div>
				</div>
				<div class="flex flex-row items-center space-x-4">
					<div class="rounded-full border border-gray-200 bg-white p-2 text-gray-700 shadow-sm">
						<BotIcon class="h-5 w-5" />
					</div>
					<div>
						<h1 class="text-base font-semibold">Unlimited AI Tutor sessions</h1>
						<p class="text-base text-gray-500">Learn alongside an AI tutor.</p>
					</div>
				</div>
				<div class="flex flex-row items-center space-x-4">
					<div class="rounded-full border border-gray-200 bg-white p-2 text-gray-700 shadow-sm">
						<MessageSquareCode class="h-5 w-5" />
					</div>
					<div>
						<h1 class="text-base font-semibold">GPT 4o Access</h1>
						<p class="text-base text-gray-500">The best AI model out there. (ChatGPT Plus)</p>
					</div>
				</div>
			</div>
		</div>
		<div class="mt-6 grid grid-cols-2 gap-6">
			<div class="rounded-md border border-gray-150 bg-white px-4 py-3">
				<div class="flex flex-row items-center justify-between">
					<h1 class="text-sm font-medium text-black">Monthly</h1>
					<p class="rounded-full bg-primary px-2 py-1 text-xs text-white opacity-0">Save 20%</p>
				</div>
				<div class="mt-1">
					<p class="text-3xl font-bold">{{ priceStr(plan, "monthly") }}</p>
					<p class="text-light mt-1 text-sm text-gray-500">Billed monthly</p>
				</div>
				<spinning-button
					:spinning="upgradingToPlus"
					:styled="false"
					class="mt-3 flex w-full flex-row items-center justify-center rounded-full bg-secondary py-2 text-lg font-semibold text-white transition-opacity hover:opacity-75"
					@click="upgradeToPlus('monthly')">
					Get Started
				</spinning-button>
			</div>
			<div class="rounded-md border border-gray-150 bg-white px-4 py-3">
				<div class="flex flex-row items-center justify-between">
					<h1 class="text-sm font-medium text-black">Yearly</h1>
					<p class="rounded-full bg-primary px-2 py-1 text-xs text-white">Save 20%</p>
				</div>
				<div class="mt-1">
					<p class="text-3xl font-bold">{{ priceStr(plan, "yearly") }}</p>
					<p class="text-light mt-1 text-sm text-gray-500">Billed yearly</p>
				</div>
				<spinning-button
					:spinning="upgradingToPlus"
					:styled="false"
					class="mt-3 flex w-full flex-row items-center justify-center rounded-full bg-secondary py-2 text-lg font-semibold text-white transition-opacity hover:opacity-75"
					@click="upgradeToPlus('yearly')">
					Get Started
				</spinning-button>
			</div>
		</div>
		<p class="mt-4 max-w-[400px] text-base text-gray-700">
			We give <strong>Ultimate</strong> for free for educators, and offer discounts for schools. Reach out to us at
			<a
				class="text-primary underline"
				href="mailto:hello@scholarly.so"
				>hello@scholarly.so</a
			>.
		</p>
	</modal>
</template>
<script lang="ts" setup>
import axios from "axios";
import { BotIcon, FileText, MessageSquareCode, TypeIcon } from "lucide-vue-next";
import { Plan } from "~/utils/user";
import { PRO_PLAN, ULTIMATE_PLAN } from "~/utils/premium_plans";

const { user } = useAuth();
const upgradingToPlus = ref(false);
const plan = ref<Plan>(getCurrentActivePlan() === Plan.FREE ? Plan.ULTIMATE : getCurrentActivePlan() === Plan.PLUS ? Plan.ULTIMATE : Plan.PLUS);
const { closePlanModal } = usePlanModal();

const { priceStr } = useLocalizedPrices();

onMounted(() => {
	getPosthog().capture("plan_modal_viewed", {
		plan: plan.value,
	});
});

async function upgradeToPlus(rec: "monthly" | "yearly") {
	if (upgradingToPlus.value) return;

	upgradingToPlus.value = true;

	getPosthog().capture("plan_modal_clicked", {
		plan: plan.value,
		rec,
	});

	try {
		const { data } = await axios.get(`/stripe/checkout_session?plan=${plan.value}&rec=` + rec);

		//open another url in a new tab
		window.open(data.url, "_blank");
	} catch (e) {
		console.error(e);
	} finally {
		upgradingToPlus.value = false;
	}
}
</script>
