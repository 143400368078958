<template>
	<div
		v-if="getCurrentActivePlanFull() && getCurrentActivePlanFull()!!.isFreeTrial && isPlanActive()"
		class="cursor-pointer rounded-md border border-gray-300 bg-white px-4 py-3"
		role="button"
		@click="togglePlanModal">
		<h1 class="text-sm font-bold">Free Trial</h1>
		<p class="mt-1 text-xs">
			You're currently on the Free trial, your trial expires in <strong>{{ getTrialExpiration() }}</strong
			>.
		</p>
		<p class="mt-4 text-xs text-primary">Upgrade Plan</p>
	</div>
	<div
		v-if="getCurrentActivePlan() !== Plan.ULTIMATE || !isPlanActive()"
		class="rounded-md border border-gray-150 bg-white px-4 py-3 shadow-sm">
		<h1 class="text-sm font-bold">Try Ultimate</h1>
		<p class="mt-1 text-xs text-gray-500">Upgrade for unlimited AI messages, and more.</p>
		<div class="mt-4 h-1 rounded-lg bg-gray-200">
			<div
				:style="{ width: widthBar + '%' }"
				class="h-1 flex-grow rounded-lg bg-primary"></div>
		</div>
		<button
			class="scholarly-button-normal mt-4 !border-none !bg-secondary !text-white"
			@click="togglePlanModal">
			<span> Learn More </span>
			<ArrowRightIcon class="h-4 w-4" />
		</button>
	</div>
</template>
<script lang="ts" setup>
import { usePlanModal } from "~/composables/usePlanModal";
import { isPlanActive, Plan } from "~/utils/user";
import { ArrowRightIcon } from "@heroicons/vue/24/outline";

const { togglePlanModal } = usePlanModal();
// const { getUsedAutoCompletes, getAutocompleteCap } = useAutocomplete();
const { accruedLimit } = useAIChat();

const { user } = useAuth();

const widthBar = computed(() => {
	return (accruedLimit.value / 1000) * 100;
});

function getTrialExpiration() {
	if (!user.value) return "";
	if (!user.value?.plan) {
		return "";
	}

	const timeDifference = new Date(user.value!!.plan.endAt).getTime() - new Date().getTime(); // Time difference in milliseconds

	// Define time units in milliseconds
	const oneDay = 24 * 60 * 60 * 1000;
	const oneHour = 60 * 60 * 1000;
	const oneMinute = 60 * 1000;

	if (timeDifference > oneDay) {
		const daysRemaining = Math.floor(timeDifference / oneDay);
		return `${daysRemaining} days`;
	} else if (timeDifference > oneHour) {
		const hoursRemaining = Math.floor(timeDifference / oneHour);
		return `${hoursRemaining} hours`;
	} else if (timeDifference > oneMinute) {
		const minutesRemaining = Math.floor(timeDifference / oneMinute);
		return `${minutesRemaining} minutes`;
	} else if (timeDifference > 0) {
		return "soon";
	} else {
		return "expired";
	}
}
</script>
