<template>
	<NuxtLayout>
		<NuxtPage />
	</NuxtLayout>
	<plan-modal
		v-if="planModalOpen"
		@close="closePlanModal" />
</template>
<script lang="ts" setup>
const { planModalOpen, closePlanModal } = usePlanModal();
</script>
